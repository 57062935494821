import { IObject } from '../../interfaces';

export const mnemonicsMapper: IObject = {
  md: 'MD',
  DateTime: 'DateTime',
  Operation: 'Operation',
  RecType: 'RecType', // ??
  Bit_Dist: 'Bit_Dist',
  LagDepth: 'LagDepth',
  LagCutDepth: 'LagCutDepth',
  HKLD: 'HKLD',
  mud_pressure: 'SPP',
  pump_cnt1: 'SP1',
  pump_cnt2: 'SP2',
  pump_cnt3: 'SP3',
  table_torque: 'T_TRQ',
  rotor_speed: 'RPM',
  tr_block_pos: 'BLOCK',
  wob: 'WOB',
  pipe_wrench_torque: 'P_TRQ',
  volume1: 'V1',
  volume2: 'V2',
  volume3: 'V3',
  volume4: 'V4',
  volume5: 'V5',
  volume6: 'V6',
  volume7: 'V7',
  volume8: 'V8',
  active_volume: 'A_V',
  slugging_volume: 'S_V',
  reception_volume: 'R_V',
  mud_density_in: 'MDI',
  mud_density_out: 'MDO',
  mud_temp_in: 'MTI',
  mud_temp_out: 'MTO',
  mud_cond_in: 'MCI',
  mud_cond_out: 'MCO',
  circ_rate_in: 'CRI',
  circ_rate_out: 'CRO',
  trip_rate: 'TRIP',
  drilling_rate: 'DR_RATE',
  // drilling_time_log: 'DR_T_L' // непонятно что это и в каких единицах
  rop: 'ROP',
  summ_gaz: 'SG',
  summ_int: 'SGI',
  summ_gaz3: 'SGO',
  c1: 'C1',
  c2: 'C2',
  c3: 'C3',
  c4: 'C4',
  c5: 'C5',
  c6: 'C6',
  akb_wrench_torque: 'A_TRQ',
  obem_emkosti: 'obem_emkosti',
  // parametr7: 'parametr7', // хз что это
  zts_glubina: 'mwdDepth',
  zts_zenit: 'mwdAngle',
  zts_azimut: 'mwdAzi',
  zts_otkl: 'mwdDeviation',
  glubina_instrumenta: 'toolDepth',
  zakachannyj_rastvor: 'liquidUpload',
  cem_davl: 'cemDavl',
  cem_calc_rasx: 'cemCalcRasx',
  cem_rasx: 'cemRasx',
  cem_plotn: 'cemPlotn',
  cem_stage: 'cemStage',
  cem_v: 'cemV',
  cem_vsumm: 'cemVSumm',
  cem_temp: 'cemTemp',
  // cem_plan_rasx: '', // Хз что ето
  zts_davl: 'mwdPressure',
  zts_vk: 'mwdVibr',
  // zts_zenit_ndm: 'mwdAngleNdm', // Хрен его знает (не отображать пока)
  gk: 'Gk',
  gk_ndm: 'gkNdm',
  zts_vnutr_davl: 'mwdInPress',
  zts_p_osev: 'mwdWob',
  zts_obrot: 'mwdRotateTool',
  zts_upit: 'mwdVoltage',
  // zts_rnagr_ndm: 'mwdResistanceNdm', ???? (не показывать)
  zts_rnagr: 'mwdResistance',
  zts_temp: 'mwdTemp',
  zts_gk_v: 'mwdGkUp',
  zts_gk_n: 'mwdGrDown',
  obem_rastvora: 'liquidV'
};

export const curveNameMapper: IObject = {
  md: 'Глубина по стволу',
  DateTime: 'Дата и время',
  HKLD: 'Вес на крюке',
  mud_pressure: 'Давление на манифольде',
  pump_cnt1: 'Ходы насоса 1',
  pump_cnt2: 'Ходы насоса 2',
  pump_cnt3: 'Ходы насоса 3',
  table_torque: 'Крутящий момент ротора',
  rotor_speed: 'Обороты ротора',
  tr_block_pos: 'Положение талевого блока',
  wob: 'Нагрузка на долото',
  woh: 'Вес на крюке',
  pipe_wrench_torque: 'Момент на ключе',
  volume1: 'Объем в емкости 1',
  volume2: 'Объем в емкости 2',
  volume3: 'Объем в емкости 3',
  volume4: 'Объем в емкости 4',
  volume5: 'Объем в емкости 5',
  volume6: 'Объем в емкости 6',
  volume7: 'Объем в емкости 7',
  volume8: 'Объем в емкости 8',
  active_volume: 'Объем в активных емкостях',
  slugging_volume: 'Объем в доливных емкостях',
  reception_volume: 'Объем в приемных емкостях',
  mud_density_in: 'Плотность раствора на выходе',
  mud_density_out: 'Плотность раствора на выходе',
  mud_temp_in: 'Температура раствора на входе',
  mud_temp_out: 'Температура раствора на выходе',
  mud_cond_in: 'Электропроводность раствора на входе',
  mud_cond_out: 'Электропроводность на выходе',
  circ_rate_in: 'Расход промывочной жидкости на входе',
  circ_rate_out: 'Расход промывочной жидкости на выходе',
  trip_rate: 'Скорость спуско-подъемной операции',
  drilling_rate: 'Скорость механическая',
  // drilling_time_log: 'Время бурения (но это не точно)',
  rop: 'Скорость бурения',
  akb_wrench_torque: 'Момент на ключе АКБ',
  summ_int: 'Сумма внутреннего газа',
  summ_gaz: 'Сумма газов',
  summ_gaz3: 'Сумма внешнего газа',
  c1: 'Метан',
  c2: 'Этан',
  c3: 'Пропан',
  c4: 'Бутан',
  c5: 'Пентан',
  c6: 'Гексан',
  zts_glubina: 'Глубина датчика телесистемы',
  zts_zenit: 'Зенитный угол телесистемы',
  zts_azimut: 'Азимут телесистемы',
  zts_otkl: 'Угол отклонителя',
  glubina_instrumenta: 'Глубина инструмента',
  zakachannyj_rastvor: 'Закаченный раствор',
  cem_davl: 'Давление цемента',
  cem_calc_rasx: 'Плановый расход цемента',
  cem_rasx: 'Расход цемента',
  cem_plotn: 'Плотность цемента',
  cem_stage: 'Номер стадии цемента',
  cem_v: 'Объем этапа цемента',
  cem_vsumm: 'Суммарный объем цемента',
  cem_temp: 'Температура цемента',
  zts_davl: 'Давление на телесистеме',
  zts_vk: 'Вибрации',
  gk: 'Гамма-каротаж',
  gk_ndm: 'Гамма-каротаж усредненный',
  zts_vnutr_davl: 'Внутреннее давление телесистемы',
  zts_p_osev: 'Нагрузка на долото телесистемы',
  zts_obrot: 'Обороты вала двигателя телесистемы',
  zts_upit: 'Напряжение телесистемы',
  zts_rnagr: 'Сопротивление нагрузки телесистемы',
  zts_temp: 'Температура телесистемы',
  zts_gk_v: 'Верхний датчик гамма-каротажа',
  zts_gk_n: 'Нижний датчик гамма-каротажа',
  obem_rastvora: 'Объем раствора'
};

export const curveUnitsMapper: IObject = {
  md: 'м',
  DateTime: '',
  HKLD: 'т',
  mud_pressure: 'МПа',
  pump_cnt1: 'Ход/мин',
  pump_cnt2: 'Ход/мин',
  pump_cnt3: 'Ход/мин',
  table_torque: 'кН*м ',
  rotor_speed: 'Об/мин',
  tr_block_pos: 'м',
  wob: 'т',
  woh: 'т',
  pipe_wrench_torque: 'кН*м ',
  volume1: 'м3',
  volume2: 'м3',
  volume3: 'м3',
  volume4: 'м3',
  volume5: 'м3',
  volume6: 'м3',
  volume7: 'м3',
  volume8: 'м3',
  active_volume: 'м3',
  slugging_volume: 'м3',
  reception_volume: 'м3',
  mud_density_in: 'г/см3',
  mud_density_out: 'г/см3',
  mud_temp_in: '°C',
  mud_temp_out: '°C',
  mud_cond_in: 'Ом*м',
  mud_cond_out: 'Ом*м',
  circ_rate_in: 'л/с ',
  circ_rate_out: 'л/с ',
  trip_rate: 'м/час',
  drilling_rate: 'м/час',
  drilling_time_log: 'час',
  rop: 'м/час',
  akb_wrench_torque: 'кН*м ',
  summ_int: '%',
  summ_gaz: '%',
  summ_gaz3: '%',
  c1: '%',
  c2: '%',
  c3: '%',
  c4: '%',
  c5: '%',
  c6: '%',
  zts_glubina: 'м',
  zts_zenit: 'град',
  zts_azimut: 'град',
  zts_otkl: 'град',
  glubina_instrumenta: 'м',
  zakachannyj_rastvor: 'м3',
  cem_davl: 'атм',
  cem_calc_rasx: 'л/с',
  cem_rasx: 'л/с',
  cem_plotn: 'г/см3',
  cem_stage: '',
  cem_v: 'м3',
  cem_vsumm: 'м3',
  cem_temp: '°С',
  zts_davl: 'атм',
  zts_vk: '',
  gk: 'Мкр/ч',
  gk_ndm: 'Мкр/ч',
  zts_vnutr_davl: 'атм',
  zts_p_osev: 'Кн',
  zts_obrot: 'Об/мин',
  zts_upit: 'вольт',
  zts_rnagr: 'Ом*м',
  zts_temp: '°C',
  zts_gk_v: 'Мкр/ч',
  zts_gk_n: 'Мкр/ч',
  obem_rastvora: 'м3'
};
