import request from '../../utils/http/request';
import options from './options';
import { getUrl } from '../utils';
import {
  IWellDirectory, EditTrunkType, TrunkType, CreateTrunkType
} from './types';
import { TrunkName } from '../../pages/project-documentation/pages/well-doc/construction/types';
import { ICopyWell } from 'models/well/types';

const { apiUrl } = options;

const url = getUrl(apiUrl);
const urlFact = getUrl(apiUrl + '/fact');

export const getTrunksApi = async (id: string): Promise<TrunkType[]> => request.get(url(`${id}/tubular`));
export const getTrunksFactApi = async (id: string): Promise<TrunkType[]> => request.get(urlFact(`${id}/tubular`));

export const getTrunkByIdApi = async (globalId: number): Promise<TrunkType> => request.get(url(`tubular/${globalId}`));
export const getTrunkByIdApiFact = async (globalId: number): Promise<TrunkType> => request.get(urlFact(`tubular/${globalId}`));

export const getWellboreDirectoryApi = async (id: string): Promise<IWellDirectory[]> => request.get(url(`${id}/tubular-directory`));
export const getWellboreDirectoryApiFact = async (id: string): Promise<IWellDirectory[]> => request.get(urlFact(`${id}/tubular-directory`));

export const createTrunkApi = async (id: string, trunkData: CreateTrunkType): Promise<TrunkType> => request.post(url(`${id}/tubular`), trunkData);
export const createTrunkApiFact = async (id: string, trunkData: CreateTrunkType): Promise<TrunkType> => request.post(urlFact(`${id}/tubular`), trunkData);

export const getDataToEditTrunkApi = async (): Promise<IWellDirectory[]> => request.get('/api/well/tubular/tubular-directory');
export const getDataToEditTrunkFactApi = async (): Promise<IWellDirectory[]> => request.get(urlFact(`/tubular-directory`));

export const constructionCopy = async (body: ICopyWell): Promise<null> => request.post(url('copy/copyPlanToPlan'), body);

export const deleteTrunkApi = async (id: string, trunkName: TrunkName): Promise<unknown> => {
  const res = await request.delete(url(`${id}/tubular/${trunkName}`));
  return trunkName;
};
export const deleteTrunkApiFact = async (id: string, trunkName: TrunkName): Promise<unknown> => {
  const res = await request.delete(urlFact(`${id}/tubular/${trunkName}`));
  return trunkName;
};

export const editTrunkApi = async (id: string, data: EditTrunkType): Promise<TrunkType> => request.put(url(`${id}/tubular/${data.name}`), data);
export const editTrunkApiFact = async (id: string, data: EditTrunkType): Promise<TrunkType> => request.put(urlFact(`${id}/tubular/${data.name}`), data);

export const editOpenAndCenterTrunkApi = async (id: string, trunks: EditTrunkType[]): Promise<TrunkType[]> => Promise.all(trunks.map(tr => request.put(url(`${id}/tubular/${tr.name}`), tr)));
export const editOpenAndCenterTrunkApiFact = async (id: string, trunks: EditTrunkType[]): Promise<TrunkType[]> => Promise.all(trunks.map(tr => request.put(urlFact(`${id}/tubular/${tr.name}`), tr)));

// export const editTrunksApi = async (id: string, trunks: EditTrunkType[]): Promise<TrunkType[]> => {
//     const requests = trunks.map(trunk => {
//         const url = `${baseUrl + id}/tubular/${trunk.name}`;
//         return axios.put(url, trunk);
//     });
//
//     const res = await Promise.all(requests);
//     console.log('res', res);
// };
