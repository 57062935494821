import { JSX, useEffect, useState } from 'react';
import Loading from 'components/loader';
import Auth from 'contexts/auth';
import { SignInBody } from 'models/auth/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectAuthState } from '../../models/auth/selectors';
import { checkAuth, signIn, signOut } from '../../models/auth/actions';

export default ({ children }: { children: JSX.Element }): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuthState);

  const onCheckAuth = async () => {
    dispatch(checkAuth())
      .then(() => setLoading(false));
  };

  const onLogin = async (userValues: SignInBody) => {
    setLoading(true);
    try {
      dispatch(signIn(userValues));
    } finally {
      setLoading(false);
    }
  };

  const onLogout = async () => {
    setLoading(true);
    try {
      if (user) {
        dispatch(signOut(user.userLogin));
      }
    } catch (e) {
      // message.error('Не удалось выполнить выход из системы');
      window.location.reload();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onCheckAuth();
  }, []);

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <Auth.Provider value={{
      user,
      onLogout,
      onLogin
    }}
    >
      {children}
    </Auth.Provider>
  );
};
