import {
  ReactNode, useCallback, useContext, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import SockJS from 'sockjs-client';
import {
  Client, Message, StompSubscription
} from '@stomp/stompjs';

import WebsocketContext from 'contexts/ws';
import Auth from '../../contexts/auth';
import { useToast } from '../hooks';

type SubscribeArgs = {
  destination: string;
  cb: any;
};

type UnsubscribeArgs = {
  topicId: string;
  cb: any;
};

export default ({ children }: { children: ReactNode }) => {
  const [clientConnected, setClientConnected] = useState(false);
  const reconnect = useRef<boolean>(false);
  const { user } = useContext(Auth);
  const inConn = useRef(false);
  const { t } = useTranslation('common');

  const { showToast } = useToast();

  // массив-очередь для хранения подписчиков пока нет коннекта
  const waiters = useRef<SubscribeArgs[]>([]);

  // мапа с id подписок для отписки
  const unsubscribeCallbacks = useRef<Map<string, UnsubscribeArgs>>(new Map());

  const stompClient = useRef<Client | null>(null);

  // подписка
  const makeSubscr = useCallback((client: Client, topicPath: string, messageCallback: any): StompSubscription => {
    const result = client.subscribe(topicPath, (message: Message) => {
      if (message) {
        const data = JSON.parse(message.body);
        messageCallback(data);
      }
    });
    // сохраняем id для отписки
    // и сохроняем коллбэк для подписки в топик при реконнекте
    unsubscribeCallbacks.current.set(topicPath, {
      topicId: result.id,
      cb: messageCallback
    });
    return result;
  }, [stompClient.current]);

  // коннект
  const connect = useCallback((headers: any): void => {
    stompClient.current?.activate();
  }, [stompClient.current]);

  // подписка на топик по названию топика (хедеры для коннекта на всякий)
  const subscribe = useCallback(async (destination: string, cb: any, headers: any = {}) => {
    if (stompClient.current) {
      // если уже законнектились - подписываемся
      if (stompClient.current.connected) {
        makeSubscr(stompClient.current, destination, cb);
      } else {
        // если коннекта не было

        // добавляем в "очередь" данные для будущей подписки
        waiters.current.push({ destination, cb });

        // если сейчас никто не коннектится - коннектимся
        if (!inConn.current) {
          try {
            inConn.current = true;
            await connect(headers);
          } catch (e: any) {
            showToast.error(e, t('ws-connect-error'));
          } finally {
            inConn.current = false;
          }
        }
      }
    }
  }, [stompClient.current]);

  const createClient = () => {
    stompClient.current = new Client({
      webSocketFactory: () => new SockJS('/api/wellWebSockets'),
      // debug: (log) => console.log('log', log),
      reconnectDelay: 20000,
      onConnect: frame => {
        setClientConnected(true);
        if (reconnect.current) {
          const arr: { path: string, cb: any }[] = [];

          unsubscribeCallbacks.current.forEach((unsub, key) => {
            arr.push({
              path: key,
              cb: unsub.cb
            });
            unsubscribeCallbacks.current.delete(key);
          });

          arr.forEach((el: any) => {
            subscribe(el.path, el.cb).catch(err => console.error('error sub on reconnect', err));
          });
          reconnect.current = false;
        }
      },
      onDisconnect: frame => setClientConnected(false),
      onStompError: () => setClientConnected(false),
      maxWebSocketChunkSize: 8 * 1024,
      splitLargeFrames: true,
      onWebSocketClose: evt => {
        reconnect.current = true;
      },
      onWebSocketError: evt => {
        reconnect.current = true;
      }
    });
  };

  !stompClient.current && createClient();

  const disconnect = () => stompClient.current?.deactivate();

  // отписка от топика по названию топика
  const unsubscribe = (topicPath: string) => {
    if (stompClient.current) {
      // ищем запись по пути топика и вызываем отписку
      const unsubscribeObject = unsubscribeCallbacks.current.get(topicPath);
      unsubscribeObject && stompClient.current.unsubscribe(unsubscribeObject.topicId);
      // удаляем запись из мапы
      unsubscribeCallbacks.current.delete(topicPath);
    }
  };

  useEffect(() => {
    // дисконнект при логауте
    if (stompClient.current?.connected && !user) {
      disconnect();
      setClientConnected(false);
    }
  }, [user]);

  useEffect(() => {
    // когда клиент подключился, подписываемся на топики из "очереди"
    if (waiters.current.length && stompClient.current?.connected) {
      // перебираем массив-очередь
      waiters.current.forEach(({ destination, cb }) => {
        if (stompClient.current) {
          // подписываемся
          const res = makeSubscr(stompClient.current, destination, cb);
          if (res) {
            // если подписались успешно - удаляем топик из массива
            const oldValues = [...waiters.current].map((e) => ({ ...e }));
            waiters.current = oldValues.filter((elem) => elem.destination !== destination);
          }
        }
      });
    }
  }, [clientConnected]);

  return (
    <WebsocketContext.Provider value={{ subscribe, unsubscribe }}>
      {children}
    </WebsocketContext.Provider>
  );
};
