import { getUrl } from '../utils';
import options from './options';
import request from '../../utils/http/request';
import {
  CoordinateSystemType,
  CreateProjectFourthStepBodyType,
  CreateProjectThirdStepBodyType,
  IBookmarkedField, IBookmarkedWell, ICopyWell, IWellBoreByWell, IWellDetailed, IWellForm, UpdateWellStatusType, WellProjectType
} from './types';

const { apiUrl, pickupApiUrl, serviceUrl, copyApiUrl } = options;

const url = getUrl(apiUrl);
const pickUpUrl = getUrl(pickupApiUrl);
const copyUrl = getUrl(copyApiUrl);
const service = getUrl(serviceUrl);

interface IWllData {
  uid: string,
  wellAlias: string,
  wellLevelType: string,
  wellName: string,
  groundElev: number,
  ppdmGuid: string,
  rotaryTableElev: string,
  statusType: string
}

export const getWellByUidApi = async (uid: string): Promise<IWllData> => request.get(url(uid));

export const getUnassignedWellsApi = async () => request.get(url('without-facilities'));

export const getWellsApi = async (): Promise<any[]> => request.get(url(''));

// привязка скважины к кусту. создается запись в well-facility
export const updateWellFacilityApi = async (uid: string, body: any): Promise<any> => {
  const getUpdateBody = () => {
    const array = body.split('&&');
    return {
      facilityId: array[1],
      facilityType: array[0]
    };
  };

  return request.post(url(`updateWellFacility/${uid}`), getUpdateBody());
};
export const wellCopy = async (wellboreUid: string) => request.post(url(`well-tubular/copy/copyPlanToFact/${wellboreUid}`));

export const getBookmarksByUserApi = async (): Promise<IBookmarkedField[]> => request.get(pickUpUrl('find-all-by-user'));

export const addWellToFavoritesApi = async (body: Partial<IBookmarkedWell>): Promise<null> => request.post(pickUpUrl(''), body);

export const stratCopy = async (body: ICopyWell): Promise<null> => request.post(copyUrl('copyStrat'), body);

export const deleteWellFromFavoritesApi = async (body: Partial<IBookmarkedWell>): Promise<null> => request.delete(pickUpUrl(''), body);

// поиск скважин по имени
export const searchWellsByNameApi = async (wellName: string): Promise<IWellForm[]> => request.get(url(`name/${wellName}`));

export const createOrUpdateProjectThirdStepApi = async (body: CreateProjectThirdStepBodyType): Promise<any> => request.post(service('project_documentation/well'), body);

export const createOrUpdateProjectFourthStepApi = async ({ wellId, body }: CreateProjectFourthStepBodyType): Promise<any> => request.post(service(`project_documentation/${wellId}/wellbore`), body);

export const getWellProjectApi = async (wellId: string): Promise<WellProjectType> => request.get(url(`well_project/${wellId}`));
export const getWellProjectApiAll = async (trunks: string[]): Promise<WellProjectType[]> => Promise.all(trunks.map(id => request.get(url(`well_project/${id}`))));

export const updateWellProjectByIdApi = async (body: WellProjectType): Promise<any> => request.put(url('well_project/updateWellProjectById'), body);

export const getWellsDetailedByFacilityIdApi = async (id: string): Promise<IWellDetailed[]> => {
  const getDetailParams = () => {
    const array = id.split('&&');
    return {
      facilityId: array[1],
      facilityType: array[0]
    };
  };
  // return request.post(url('well-detail'), getDetailParams());
  return listData as IWellDetailed[];
};

export const getWellStatusApi = (wellId: string): Promise<string> => request.get(url(`getStatusType/${wellId}`));

export const updateWellStatusApi = (body: UpdateWellStatusType): Promise<void> => request.post(url('updateStatusTypeById'), body);

export const getWellboreByWellIdApi = async (ids: string[]): Promise<IWellBoreByWell[]> => request.post(url('findWellboreByWellId'), ids);

export const getCoordinateSystemApi = async (): Promise<CoordinateSystemType[]> => request.get('/api/catalog/crs');

var listData = [
  {
    uid: 'f08b2ffb-1fb1-4c5e-8a60-53031b7391e2',
    wellName: '31061',
    projectDocStatus: 'Согласовано',
    incidentCount: 3,
    wellBoreList: [
      {
        uid: 'f08b2ffb-1fb1-4c5e-8a60-53031b7391e2-1',
        wellBoreName: '31061-1'
      },
      {
        uid: 'f08b2ffb-1fb1-4c5e-8a60-53031b7391e2-2',
        wellBoreName: '31061-2'
      },
      {
        uid: 'f08b2ffb-1fb1-4c5e-8a60-53031b7391e2-3',
        wellBoreName: '31061-3'
      }
    ]
  },
  {
    uid: '0671768d-22e0-4758-84b7-0ee1b7503de2',
    wellName: '31003',
    projectDocStatus: 'Согласовано',
    incidentCount: 3,
    wellBoreList: [
      {
        uid: '0671768d-22e0-4758-84b7-0ee1b7503de2-1',
        wellBoreName: '31003-1'
      },
      {
        uid: '0671768d-22e0-4758-84b7-0ee1b7503de2-2',
        wellBoreName: '31003-2'
      }
    ]
  },
  {
    uid: '85c1d76f-e825-4851-8bdc-1bebe14410ef',
    wellName: '31006',
    projectDocStatus: 'Согласовано',
    incidentCount: 3,
    wellBoreList: [
      {
        uid: '85c1d76f-e825-4851-8bdc-1bebe14410ef-1',
        wellBoreName: '31006-1'
      },
      {
        uid: '85c1d76f-e825-4851-8bdc-1bebe14410ef-2',
        wellBoreName: '31006-2'
      },
      {
        uid: '85c1d76f-e825-4851-8bdc-1bebe14410ef-3',
        wellBoreName: '31006-3'
      }
    ]
  },
  {
    uid: '26e438e0-f39e-4656-8430-e10122d7e0e5',
    wellName: '31056',
    projectDocStatus: 'Согласовано',
    incidentCount: 3,
    wellBoreList: [
      {
        uid: '26e438e0-f39e-4656-8430-e10122d7e0e5-1',
        wellBoreName: '31056-1'
      }
    ]
  }
];
