import {
  TabletConfigStateType,
  TrackStateElement
} from 'reducers/view/tablet-tracks';
import { curveNameMapper, curveUnitsMapper } from 'models/well-log/constants';
import { CurveDataType } from 'models/well-log/types';
import { uniq } from 'lodash';
import { colors, selectedWidth, typeItems } from './constants';
import { BaseConfig } from './new-tablet-raw-1.5.16/interfaces/Config';

export type WellMultiselectItemType = {
  name: string;
  code: string;
  unit: string;
};

// название мнемоники с единицами измерения
export const getFullCurveName = (code: string | null) =>
  (code
    ? `${curveNameMapper[code] || ''}${curveUnitsMapper[code] ? `, ${curveUnitsMapper[code]}` : ''
    }`
    : '');

// формирование массива для xAxis для конфига
export const convertTracksStateToXAxis = (tracksState: TrackStateElement[]) => {
  const result: any = [];
  const copyState: TrackStateElement[] = JSON.parse(
    JSON.stringify(tracksState)
  );
  copyState.forEach((chartArray) => {
    let logarithmic = false;
    const charts = chartArray.charts.map((elem, index) => {
      if (elem.logarithmic) {
        logarithmic = elem.logarithmic;
      }

      return {
        ...elem,
        name: elem.code,
        color: elem.color ? elem.color : colors[index],
        title: getFullCurveName(elem.code)
      };
    });

    result.push({
      gridIntervalCount: 4,
      title: '',
      charts,
      logarithmic
    });
  });
  return result || [];
};

// формирование конфига из базового и массива графиков
export const addXAxisToBaseConfig = (
  baseConfig: BaseConfig,
  tracksState: TrackStateElement[]
) => ({ ...baseConfig, xAxis: convertTracksStateToXAxis(tracksState) });

// список возможных мнемоник для выпадающих списков из маппера
export const getCurveList = () => {
  const result: WellMultiselectItemType[] = [];
  Object.keys(curveNameMapper).forEach((key) => {
    if (key !== 'MD' && key !== 'DateTime') {
      result.push({
        name: curveNameMapper[key],
        code: key,
        unit: curveUnitsMapper[key]
      });
    }
  });
  result.sort((a, b) => a.name.localeCompare(b.name));
  return result;
};

// получение шаблона треков
export const getStartTemplate = (templateName: string): TrackStateElement[] => {
  const typeItem = typeItems.find((e) => e.value === templateName);
  return typeItem?.template || [];
};

// выделение активной кривой жирным
export const updateActiveCurveInTracks = (
  state: TabletConfigStateType,
  activeCurveName: string | null
) => {
  if (activeCurveName === null) {
    state.trackState.forEach((track) =>
      track.charts.map((curve) => {
        if (curve.code === state.prevActiveCurve?.code) {
          curve.lineWidth = state.prevActiveCurve?.prevLineWidth;
        }

        return curve;
      }));
    state.prevActiveCurve = null;
    return;
  }

  state.trackState.forEach((track) =>
    track.charts.map((curve) => {
      if (curve.code === activeCurveName) {
        state.prevActiveCurve = {
          code: curve.code,
          name: curve.name,
          prevLineWidth: undefined
        };

        if (curve.lineWidth) {
          state.prevActiveCurve.prevLineWidth = curve.lineWidth;
          curve.lineWidth += 1;
        } else {
          curve.lineWidth = selectedWidth;
        }
      }

      return curve;
    }));
};

// данные для таблицы в модалке
export const getModalDataByCode = (
  curveCode: string,
  data: CurveDataType[]
) => {
  // поиск данных по активной кривой
  const activeCurveData = data.find(
    (element: CurveDataType) => element.name === curveCode
  );

  const result: any[] = [];
  activeCurveData?.data?.forEach((point: any) => {
    result.push({
      dateTime: point[2] || '-',
      deep: point[1] || '-',
      value: point[0] || '-'
    });
  });
  return result;
};

/**
 * Возвращает список уникальных мнемониг из треков
 * @param tracks
 */
export const getUniqTrackCodes = (tracks: TrackStateElement[]) => uniq(tracks.map((track) =>
  track.charts.map((chart) => chart.code)).flat()).toString();