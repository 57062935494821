import { JSX, useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Auth from 'contexts/auth';

type PrivateType = {
  redirectPath?: string;
  roles?: number[];
  children?: any
};

export default ({
  redirectPath = '/', roles = [], children
}: PrivateType): JSX.Element => {
  const { user } = useContext(Auth);
  const location = useLocation();

  if (!user) {
    return <Navigate to={redirectPath} replace state={{ from: location.pathname }} />;
  }

  // if (!user || !roles.includes(roleId)) {
  //     return <Navigate to={redirectPath} replace state={{from: location.pathname}} />;
  // }

  return children || <Outlet />;
};
