import { getUrl } from '../utils';
import request from '../../utils/http/request';
import options from './options';
import { MnemonicsLatestResponse, MnemonicsResponse } from './types';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export type getMnemonicsForPeriodProps = {
  wellId: string;
  from: Date;
  to: Date;
  granularityMS: number;
  mnemonics: string[];
};

// get mock Data by sending XML
export const getWellLogs = (xmlFile: File | null) => {
  const formData = new FormData();
  xmlFile && formData.append('objLogsFile', xmlFile);
  return request.post('mnemonics/well/logs/data/mnemonics', formData);
};

export const getMnemonicNamesForWell = (
  wellId: string
): Promise<Record<string, string>> =>
  request.get(`/api/querying/mnemonic_meta/${wellId}`);

// получение массива мнемоник для скважины по диапазону
export const getMnemonicsForPeriodApi = async ({
  wellId,
  from,
  to,
  mnemonics,
  granularityMS
}: getMnemonicsForPeriodProps): Promise<MnemonicsResponse> => {
  const preparedMnemonic = mnemonics
    .map((mn) => `mnemonics=${mn}`)
    .join('&');
  return request.get(`/api/querying/mnemonic/${wellId}?${preparedMnemonic}`, {
    params: {
      beginDateTimeInclusive: from.toISOString(),
      granularityMS,
      endDateTimeExclusive: to.toISOString()
    }
  });

  // return await newSampleData;
};

// Получить последние значения мнемоник для скважины
export const getMnemonicsLatest = (
  wellId: string,
  mnemonics: string
): Promise<Record<string, string>> =>
  request.get(`/api/querying/mnemonic/latest/${wellId}`, {
    params: {
      mnemonics
    }
  });

// получение последних значений мнемоник для скважины
export const getLastMnemonicApi = async (
  wellId: string,
  countMsPeriod: number,
  granularityMS = 0
): Promise<MnemonicsLatestResponse> =>
  request.get(`/api/querying/mnemonic/last/${wellId}`, {
    params: {
      countMsPeriod,
      granularityMS
    }
  });

// данные для теста вебсокетов и планшета:
// первый джсон
// [
//     {
//         "meta": [],
//         "dataset": [
//             {
//                 "name": "MTO",
//                 "data": [
//                     [
//                         1.14,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         1.14,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         1.14,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         1.14,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         1.14,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         1.14,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         1.14,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         1.14,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         1.14,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         1.14,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "TRIP",
//                 "data": [
//                     [
//                         55.757751,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         59.386818,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         60.091282,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         61.051918,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         61.393475,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         62.247375,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         64.061905,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         65.236015,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         65.257362,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         65.214668,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "drilling_time_log",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "C1",
//                 "data": [
//                     [
//                         6.827588,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         7.775306,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         7.77454,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         7.774837,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         7.77493,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         7.775135,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         7.775235,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         7.775448,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         7.775322,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         7.774479,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "C2",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "RPM",
//                 "data": [
//                     [
//                         -999.25,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         -999.25,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         -999.25,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         -999.25,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         -999.25,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         -999.25,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         -999.25,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         -999.25,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         -999.25,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         -999.25,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "C3",
//                 "data": [
//                     [
//                         -999.25,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         -999.25,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         -999.25,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         -999.25,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         -999.25,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         -999.25,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         -999.25,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         -999.25,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         -999.25,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         -999.25,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "P_TRQ",
//                 "data": [
//                     [
//                         7.570968,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         10.7134,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         10.265121,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         10.060795,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         9.871775,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         9.65121,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         9.470675,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         9.278537,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         9.073477,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         8.875576,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "MDI",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "C4",
//                 "data": [
//                     [
//                         -999.25,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         -999.25,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         -999.25,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         -999.25,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         -999.25,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         -999.25,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         -999.25,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         -999.25,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         -999.25,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         -999.25,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "C5",
//                 "data": [
//                     [
//                         -999.25,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         -999.25,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         -999.25,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         -999.25,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         -999.25,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         -999.25,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         -999.25,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         -999.25,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         -999.25,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         -999.25,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "Operation",
//                 "data": [
//                     [
//                         2,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         2,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         2,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         2,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         2,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         2,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         2,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         2,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         2,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         2,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "C6",
//                 "data": [
//                     [
//                         -999.25,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         -999.25,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         -999.25,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         -999.25,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         -999.25,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         -999.25,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         -999.25,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         -999.25,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         -999.25,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         -999.25,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "SPP",
//                 "data": [
//                     [
//                         39.715122,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         53.005737,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         55.358063,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         55.526627,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         55.875374,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         59.890247,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         60.934914,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         60.898914,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         61.018436,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         61.95747,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "SG",
//                 "data": [
//                     [
//                         -999.25,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         -999.25,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         -999.25,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         -999.25,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         -999.25,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         -999.25,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         -999.25,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         -999.25,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         -999.25,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         -999.25,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "A_TRQ",
//                 "data": [
//                     [
//                         -999.25,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         -999.25,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         -999.25,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         -999.25,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         -999.25,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         -999.25,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         -999.25,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         -999.25,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         -999.25,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         -999.25,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "A_V",
//                 "data": [
//                     [
//                         33.249405,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         33.080093,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         33.45026,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         33.678581,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         33.971531,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         34.179165,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         34.393776,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         34.597149,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         34.839684,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         34.967907,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "MDO",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "V1",
//                 "data": [
//                     [
//                         1.300401,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0.183419,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         1.29375,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0.96033,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         1.71683,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         1.660622,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         1.63564,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         1.264283,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         2.298142,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         3.052992,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "V2",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "HKLD",
//                 "data": [
//                     [
//                         8.376306,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         10.061653,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         8.650903,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         8.995139,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         8.28317,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         8.339377,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         8.36436,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         8.735717,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         7.701858,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         6.947008,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "V3",
//                 "data": [
//                     [
//                         23.6357,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         23.497505,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         23.510769,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         23.523375,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         23.521667,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         23.524544,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         23.521473,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         23.520863,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         23.521812,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         23.525375,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "V4",
//                 "data": [
//                     [
//                         34.913635,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         34.837231,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         34.807693,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         34.77161,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         34.766624,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         34.806473,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         34.8382,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         34.85276,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         34.845928,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         34.856213,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "Bit_Dist",
//                 "data": [
//                     [
//                         37.40258,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         37.612259,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         37.805805,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         38.00742,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         38.225163,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         38.418709,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         38.604195,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         38.805805,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         39.015484,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         39.200966,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "V5",
//                 "data": [
//                     [
//                         8.434329,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         7.58822,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         7.495326,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         7.433353,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         7.349388,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         7.282725,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         7.216327,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         7.14257,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         7.073633,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         7.024462,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "DR_RATE",
//                 "data": [
//                     [
//                         48.002426,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         55.849869,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         58.507133,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         59.29113,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         59.994061,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         60.237259,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         61.638794,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         63.314949,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         63.914562,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         63.935482,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "V6",
//                 "data": [
//                     [
//                         8.711389,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         7.1277,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         7.105928,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         7.075509,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         7.085877,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         7.111195,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         7.147885,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         7.160776,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         7.149587,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         7.070657,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "V7",
//                 "data": [
//                     [
//                         3.045614,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         1.033333,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0.688889,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         1.074667,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0.765432,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0.688889,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0.988406,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         1.074667,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0.715385,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0.628986,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "V8",
//                 "data": [
//                     [
//                         -999.25,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         -999.25,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         -999.25,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         -999.25,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         -999.25,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         -999.25,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         -999.25,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         -999.25,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         -999.25,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         -999.25,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "WOB",
//                 "data": [
//                     [
//                         -999.25,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         -999.25,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         -999.25,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         -999.25,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         -999.25,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         -999.25,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         -999.25,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         -999.25,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         -999.25,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         -999.25,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "S_V",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "R_V",
//                 "data": [
//                     [
//                         33.249405,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         33.080093,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         33.45026,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         33.678581,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         33.971531,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         34.179165,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         34.393776,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         34.597149,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         34.839684,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         34.967907,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "LagCutDepth",
//                 "data": [
//                     [
//                         37.394516,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         37.588066,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         37.789677,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         37.999355,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         38.192902,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         38.386452,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         38.580002,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         38.789677,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         38.999355,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         39.184837,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "LagDepth",
//                 "data": [
//                     [
//                         37.394516,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         37.588066,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         37.789677,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         37.999355,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         38.192902,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         38.386452,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         38.580002,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         38.789677,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         38.999355,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         39.184837,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "BLOCK",
//                 "data": [
//                     [
//                         42.517666,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         42.803062,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         42.019054,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         41.628258,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         41.556171,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         41.493736,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         41.6082,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         41.604916,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         41.757019,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         41.539928,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "SP1",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "MCI",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "ROP",
//                 "data": [
//                     [
//                         19.70046,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         58.064518,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         87.096771,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         55.831264,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         78.3871,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         87.096771,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         60.703812,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         55.831264,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         83.870964,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         95.391708,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "T_TRQ",
//                 "data": [
//                     [
//                         0.391489,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0.41546,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0.734861,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0.741281,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0.844517,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0.811784,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0.662401,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0.627974,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0.806692,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         1.050086,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "SP3",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "SGI",
//                 "data": [
//                     [
//                         -999.25,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         -999.25,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         -999.25,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         -999.25,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         -999.25,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         -999.25,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         -999.25,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         -999.25,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         -999.25,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         -999.25,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "SP2",
//                 "data": [
//                     [
//                         51.676083,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         45.976379,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         45.681854,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         45.569317,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         45.473309,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         45.458164,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         45.375042,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         45.238094,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         45.129658,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         45.112782,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "CRI",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "MCO",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "RecType",
//                 "data": [
//                     [
//                         3,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         3,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         3,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         3,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         3,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         3,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         3,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         3,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         3,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         3,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "SGO",
//                 "data": [
//                     [
//                         15.483871,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         9.975186,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         23.770161,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         36.029778,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         45.822582,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         52.983871,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         58.636364,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         67.92804,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         66.559143,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         69.884796,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "CRO",
//                 "data": [
//                     [
//                         0,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         0,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         0,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         0,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         0,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         0,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         0,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         0,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         0,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         0,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             },
//             {
//                 "name": "MTI",
//                 "data": [
//                     [
//                         1.14,
//                         37.39,
//                         1624886034175
//                     ],
//                     [
//                         1.14,
//                         37.58,
//                         1624886063174
//                     ],
//                     [
//                         1.14,
//                         37.78,
//                         1624886071170
//                     ],
//                     [
//                         1.14,
//                         37.99,
//                         1624886084198
//                     ],
//                     [
//                         1.14,
//                         38.19,
//                         1624886094170
//                     ],
//                     [
//                         1.14,
//                         38.38,
//                         1624886102167
//                     ],
//                     [
//                         1.14,
//                         38.58,
//                         1624886113163
//                     ],
//                     [
//                         1.14,
//                         38.78,
//                         1624886126163
//                     ],
//                     [
//                         1.14,
//                         38.99,
//                         1624886135181
//                     ],
//                     [
//                         1.14,
//                         39.18,
//                         1624886142159
//                     ]
//                 ]
//             }
//         ]
//     }
// ]

// доп точки

// export const wsTestArray = [
//     {
//         wellId: '80876afa-45ca-44ca-af9c-ba9ad9f12108',
//         dataset: [
//             {
//                 name: "HKLD",
//                 data: [
//                     [
//                         4.993343,
//                         39.38,
//                         1624886148160
//                     ],
//                     [
//                         3.994672,
//                         39.58,
//                         1624886157156
//                     ],
//                     [
//                         8.774075,
//                         39.78,
//                         1624886183153
//                     ],
//                     [
//                         6.420663,
//                         39.96,
//                         1624886189192
//                     ],
//                     [
//                         5.136011,
//                         40.19,
//                         1624886196150
//                     ],
//                     [
//                         4.294274,
//                         40.34,
//                         1624886201149
//                     ],
//                     [
//                         1.897464,
//                         40.59,
//                         1624886206152
//                     ],
//                     [
//                         3.245669,
//                         40.79,
//                         1624886212148
//                     ],
//                     [
//                         3.245669,
//                         40.95,
//                         1624886218167
//                     ],
//                     [
//                         3.91145,
//                         41.15,
//                         1624886224144
//                     ],
//                     [
//                         3.620171,
//                         41.34,
//                         1624886228145
//                     ],
//                     [
//                         3.91145,
//                         41.56,
//                         1624886234145
//                     ],
//                     [
//                         0.332883,
//                         41.74,
//                         1624886237142
//                     ],
//                     [
//                         1.414776,
//                         41.96,
//                         1624886243179
//                     ],
//                     [
//                         0.998663,
//                         42.14,
//                         1624886247143
//                     ],
//                     [
//                         0.499331,
//                         42.37,
//                         1624886255140
//                     ],
//                     [
//                         7.376933,
//                         42.58,
//                         1624886394117
//                     ],
//                     [
//                         8.151237,
//                         42.79,
//                         1624886402115
//                     ],
//                     [
//                         7.481787,
//                         42.99,
//                         1624886408158
//                     ],
//                     [
//                         6.391482,
//                         43.16,
//                         1624886413128
//                     ]
//                 ]
//             },
//             {
//                 name: "T_TRQ",
//                 data: [
//                     [
//                         1.111242,
//                         39.38,
//                         1624886148160
//                     ],
//                     [
//                         1.173188,
//                         39.58,
//                         1624886157156
//                     ],
//                     [
//                         0.590071,
//                         39.78,
//                         1624886183153
//                     ],
//                     [
//                         1.02877,
//                         39.96,
//                         1624886189192
//                     ],
//                     [
//                         1.149555,
//                         40.19,
//                         1624886196150
//                     ],
//                     [
//                         1.0301,
//                         40.34,
//                         1624886201149
//                     ],
//                     [
//                         1.256848,
//                         40.59,
//                         1624886206152
//                     ],
//                     [
//                         1.404621,
//                         40.79,
//                         1624886212148
//                     ],
//                     [
//                         1.279515,
//                         40.95,
//                         1624886218167
//                     ],
//                     [
//                         1.193183,
//                         41.15,
//                         1624886224144
//                     ],
//                     [
//                         1.199147,
//                         41.34,
//                         1624886228145
//                     ],
//                     [
//                         1.27956,
//                         41.56,
//                         1624886234145
//                     ],
//                     [
//                         1.339814,
//                         41.74,
//                         1624886237142
//                     ],
//                     [
//                         1.229034,
//                         41.96,
//                         1624886243179
//                     ],
//                     [
//                         1.160568,
//                         42.14,
//                         1624886247143
//                     ],
//                     [
//                         1.348267,
//                         42.37,
//                         1624886255140
//                     ],
//                     [
//                         0.86816,
//                         42.58,
//                         1624886394117
//                     ],
//                     [
//                         0.843696,
//                         42.79,
//                         1624886402115
//                     ],
//                     [
//                         0.950741,
//                         42.99,
//                         1624886408158
//                     ],
//                     [
//                         1.051391,
//                         43.16,
//                         1624886413128
//                     ]
//                 ]
//             },
//             {
//                 name: "ROP",
//                 data: [
//                     [
//                         120.967743,
//                         39.38,
//                         1624886148160
//                     ],
//                     [
//                         80.645164,
//                         39.58,
//                         1624886157156
//                     ],
//                     [
//                         27.915632,
//                         39.78,
//                         1624886183153
//                     ],
//                     [
//                         120.967743,
//                         39.96,
//                         1624886189192
//                     ],
//                     [
//                         116.129036,
//                         40.19,
//                         1624886196150
//                     ],
//                     [
//                         127.741936,
//                         40.34,
//                         1624886201149
//                     ],
//                     [
//                         150.967743,
//                         40.59,
//                         1624886206152
//                     ],
//                     [
//                         125.80645,
//                         40.79,
//                         1624886212148
//                     ],
//                     [
//                         106.451614,
//                         40.95,
//                         1624886218167
//                     ],
//                     [
//                         116.129036,
//                         41.15,
//                         1624886224144
//                     ],
//                     [
//                         181.451614,
//                         41.34,
//                         1624886228145
//                     ],
//                     [
//                         125.80645,
//                         41.56,
//                         1624886234145
//                     ],
//                     [
//                         241.935486,
//                         41.74,
//                         1624886237142
//                     ],
//                     [
//                         120.967743,
//                         41.96,
//                         1624886243179
//                     ],
//                     [
//                         166.935486,
//                         42.14,
//                         1624886247143
//                     ],
//                     [
//                         97.983871,
//                         42.37,
//                         1624886255140
//                     ],
//                     [
//                         40.986717,
//                         42.58,
//                         1624886394117
//                     ],
//                     [
//                         97.983871,
//                         42.79,
//                         1624886402115
//                     ],
//                     [
//                         120.967743,
//                         42.99,
//                         1624886408158
//                     ],
//                     [
//                         121.935486,
//                         43.16,
//                         1624886413128
//                     ]
//                 ]
//             }
//         ]
//     },
//     {
//         wellId: '80876afa-45ca-44ca-af9c-ba9ad9f12108',
//         dataset: [
//             {
//                 name: "HKLD",
//                 data: [
//                     [
//                         6.491349,
//                         43.38,
//                         1624886420114
//                     ],
//                     [
//                         5.243011,
//                         43.55,
//                         1624886424112
//                     ],
//                     [
//                         7.612126,
//                         43.78,
//                         1624886934029
//                     ],
//                     [
//                         8.094273,
//                         43.99,
//                         1624886946050
//                     ],
//                     [
//                         5.492679,
//                         44.18,
//                         1624886952047
//                     ],
//                     [
//                         5.207344,
//                         44.38,
//                         1624886959027
//                     ],
//                     [
//                         3.095869,
//                         44.58,
//                         1624886964025
//                     ],
//                     [
//                         5.188138,
//                         44.78,
//                         1624886975020
//                     ],
//                     [
//                         4.494008,
//                         44.99,
//                         1624886980043
//                     ],
//                     [
//                         0.79893,
//                         45.17,
//                         1624887006010
//                     ],
//                     [
//                         3.138669,
//                         45.37,
//                         1624887007009
//                     ],
//                     [
//                         5.339062,
//                         45.99,
//                         1624889945047
//                     ],
//                     [
//                         9.905176,
//                         46.19,
//                         1624890065031
//                     ],
//                     [
//                         9.455025,
//                         46.39,
//                         1624890066027
//                     ],
//                     [
//                         8.723815,
//                         46.57,
//                         1624890067037
//                     ],
//                     [
//                         6.858763,
//                         46.78,
//                         1624890068025
//                     ],
//                     [
//                         7.636376,
//                         46.98,
//                         1624890069033
//                     ],
//                     [
//                         6.867726,
//                         47.17,
//                         1624890070039
//                     ],
//                     [
//                         6.241682,
//                         47.36,
//                         1624890071026
//                     ],
//                     [
//                         5.635346,
//                         47.57,
//                         1624890072027
//                     ]
//                 ]
//             },
//             {
//                 name: "T_TRQ",
//                 data: [
//                     [
//                         0.904372,
//                         43.38,
//                         1624886420114
//                     ],
//                     [
//                         0.921651,
//                         43.55,
//                         1624886424112
//                     ],
//                     [
//                         0.727102,
//                         43.78,
//                         1624886934029
//                     ],
//                     [
//                         0.692853,
//                         43.99,
//                         1624886946050
//                     ],
//                     [
//                         1.115882,
//                         44.18,
//                         1624886952047
//                     ],
//                     [
//                         1.213249,
//                         44.38,
//                         1624886959027
//                     ],
//                     [
//                         1.201457,
//                         44.58,
//                         1624886964025
//                     ],
//                     [
//                         1.089979,
//                         44.78,
//                         1624886975020
//                     ],
//                     [
//                         1.074362,
//                         44.99,
//                         1624886980043
//                     ],
//                     [
//                         1.241654,
//                         45.17,
//                         1624887006010
//                     ],
//                     [
//                         1.209988,
//                         45.37,
//                         1624887007009
//                     ],
//                     [
//                         1.034831,
//                         45.99,
//                         1624889945047
//                     ],
//                     [
//                         0.52794,
//                         46.19,
//                         1624890065031
//                     ],
//                     [
//                         0.67728,
//                         46.39,
//                         1624890066027
//                     ],
//                     [
//                         0.793599,
//                         46.57,
//                         1624890067037
//                     ],
//                     [
//                         0.823942,
//                         46.78,
//                         1624890068025
//                     ],
//                     [
//                         0.875809,
//                         46.98,
//                         1624890069033
//                     ],
//                     [
//                         0.957681,
//                         47.17,
//                         1624890070039
//                     ],
//                     [
//                         1.027823,
//                         47.36,
//                         1624890071026
//                     ],
//                     [
//                         1.104469,
//                         47.57,
//                         1624890072027
//                     ]
//                 ]
//             },
//             {
//                 name: "ROP",
//                 data: [
//                     [
//                         116.129036,
//                         43.38,
//                         1624886420114
//                     ],
//                     [
//                         166.935486,
//                         43.55,
//                         1624886424112
//                     ],
//                     [
//                         8.960573,
//                         43.78,
//                         1624886934029
//                     ],
//                     [
//                         60.483871,
//                         43.99,
//                         1624886946050
//                     ],
//                     [
//                         130.645157,
//                         44.18,
//                         1624886952047
//                     ],
//                     [
//                         103.686638,
//                         44.38,
//                         1624886959027
//                     ],
//                     [
//                         127.741936,
//                         44.58,
//                         1624886964025
//                     ],
//                     [
//                         63.343109,
//                         44.78,
//                         1624886975020
//                     ],
//                     [
//                         174.193542,
//                         44.99,
//                         1624886980043
//                     ],
//                     [
//                         110.322578,
//                         45.17,
//                         1624887006010
//                     ],
//                     [
//                         103.686638,
//                         45.37,
//                         1624887007009
//                     ],
//                     [
//                         48.387096,
//                         45.99,
//                         1624889945047
//                     ],
//                     [
//                         51.843319,
//                         46.19,
//                         1624890065031
//                     ],
//                     [
//                         63.343109,
//                         46.39,
//                         1624890066027
//                     ],
//                     [
//                         77.419357,
//                         46.57,
//                         1624890067037
//                     ],
//                     [
//                         107.834099,
//                         46.78,
//                         1624890068025
//                     ],
//                     [
//                         103.686638,
//                         46.98,
//                         1624890069033
//                     ],
//                     [
//                         99.539169,
//                         47.17,
//                         1624890070039
//                     ],
//                     [
//                         116.129036,
//                         47.36,
//                         1624890071026
//                     ],
//                     [
//                         120.276497,
//                         47.57,
//                         1624890072027
//                     ]
//                 ]
//             }
//         ]
//     },
//     {
//         wellId: '80876afa-45ca-44ca-af9c-ba9ad9f12108',
//         dataset: [
//             {
//                 name: "HKLD",
//                 data: [
//                     [
//                         5.118177,
//                         47.77,
//                         1624890073035
//                     ],
//                     [
//                         5.253192,
//                         47.94,
//                         1624890074025
//                     ],
//                     [
//                         5.278677,
//                         48.19,
//                         1624890075056
//                     ],
//                     [
//                         4.57723,
//                         48.39,
//                         1624890076026
//                     ],
//                     [
//                         5.09321,
//                         48.59,
//                         1624890077031
//                     ],
//                     [
//                         5.159788,
//                         48.77,
//                         1624890078024
//                     ],
//                     [
//                         5.392811,
//                         48.97,
//                         1624890079025
//                     ],
//                     [
//                         3.595204,
//                         49.17,
//                         1624890080028
//                     ],
//                     [
//                         6.074093,
//                         49.36,
//                         1624890081030
//                     ],
//                     [
//                         6.606838,
//                         49.59,
//                         1624890082028
//                     ],
//                     [
//                         6.158459,
//                         49.76,
//                         1624890083028
//                     ],
//                     [
//                         6.782318,
//                         49.94,
//                         1624890084025
//                     ],
//                     [
//                         6.063348,
//                         50.19,
//                         1624890089025
//                     ],
//                     [
//                         5.892147,
//                         50.38,
//                         1624890090028
//                     ],
//                     [
//                         6.82424,
//                         50.57,
//                         1624890095021
//                     ],
//                     [
//                         6.330334,
//                         50.76,
//                         1624890098025
//                     ],
//                     [
//                         7.090552,
//                         50.97,
//                         1624890103063
//                     ],
//                     [
//                         7.681297,
//                         51.19,
//                         1624890114019
//                     ],
//                     [
//                         7.431542,
//                         51.39,
//                         1624890115019
//                     ],
//                     [
//                         7.103169,
//                         51.56,
//                         1624890117021
//                     ],
//                     [
//                         7.541288,
//                         51.79,
//                         1624890122027
//                     ],
//                     [
//                         8.232077,
//                         51.99,
//                         1624890127052
//                     ],
//                     [
//                         9.04198,
//                         52.16,
//                         1624890131021
//                     ],
//                     [
//                         8.055439,
//                         52.35,
//                         1624890135020
//                     ],
//                     [
//                         8.920196,
//                         52.56,
//                         1624890140024
//                     ],
//                     [
//                         9.180942,
//                         52.75,
//                         1624890144021
//                     ],
//                     [
//                         6.866963,
//                         52.98,
//                         1624890150047
//                     ],
//                     [
//                         4.636675,
//                         53.19,
//                         1624890157018
//                     ],
//                     [
//                         3.120836,
//                         53.35,
//                         1624890161015
//                     ],
//                     [
//                         4.351341,
//                         53.55,
//                         1624890169027
//                     ],
//                     [
//                         2.596533,
//                         53.79,
//                         1624890174012
//                     ],
//                     [
//                         22.873829,
//                         53.94,
//                         1624890178055
//                     ]
//                 ]
//             },
//             {
//                 name: "T_TRQ",
//                 data: [
//                     [
//                         1.232419,
//                         47.77,
//                         1624890073035
//                     ],
//                     [
//                         1.008001,
//                         47.94,
//                         1624890074025
//                     ],
//                     [
//                         1.099384,
//                         48.19,
//                         1624890075056
//                     ],
//                     [
//                         1.057214,
//                         48.39,
//                         1624890076026
//                     ],
//                     [
//                         1.169321,
//                         48.59,
//                         1624890077031
//                     ],
//                     [
//                         1.261482,
//                         48.77,
//                         1624890078024
//                     ],
//                     [
//                         1.194327,
//                         48.97,
//                         1624890079025
//                     ],
//                     [
//                         1.300592,
//                         49.17,
//                         1624890080028
//                     ],
//                     [
//                         1.05966,
//                         49.36,
//                         1624890081030
//                     ],
//                     [
//                         1.210491,
//                         49.59,
//                         1624890082028
//                     ],
//                     [
//                         1.226726,
//                         49.76,
//                         1624890083028
//                     ],
//                     [
//                         1.078247,
//                         49.94,
//                         1624890084025
//                     ],
//                     [
//                         1.08174,
//                         50.19,
//                         1624890089025
//                     ],
//                     [
//                         1.213212,
//                         50.38,
//                         1624890090028
//                     ],
//                     [
//                         1.111425,
//                         50.57,
//                         1624890095021
//                     ],
//                     [
//                         1.076024,
//                         50.76,
//                         1624890098025
//                     ],
//                     [
//                         1.10634,
//                         50.97,
//                         1624890103063
//                     ],
//                     [
//                         1.05401,
//                         51.19,
//                         1624890114019
//                     ],
//                     [
//                         1.108969,
//                         51.39,
//                         1624890115019
//                     ],
//                     [
//                         1.130775,
//                         51.56,
//                         1624890117021
//                     ],
//                     [
//                         0.967594,
//                         51.79,
//                         1624890122027
//                     ],
//                     [
//                         0.988543,
//                         51.99,
//                         1624890127052
//                     ],
//                     [
//                         0.692307,
//                         52.16,
//                         1624890131021
//                     ],
//                     [
//                         0.970585,
//                         52.35,
//                         1624890135020
//                     ],
//                     [
//                         0.831424,
//                         52.56,
//                         1624890140024
//                     ],
//                     [
//                         0.692307,
//                         52.75,
//                         1624890144021
//                     ],
//                     [
//                         1.169053,
//                         52.98,
//                         1624890150047
//                     ],
//                     [
//                         1.167692,
//                         53.19,
//                         1624890157018
//                     ],
//                     [
//                         1.325866,
//                         53.35,
//                         1624890161015
//                     ],
//                     [
//                         1.315421,
//                         53.55,
//                         1624890169027
//                     ],
//                     [
//                         1.264193,
//                         53.79,
//                         1624890174012
//                     ],
//                     [
//                         1.4459,
//                         53.94,
//                         1624890178055
//                     ]
//                 ]
//             },
//             {
//                 name: "ROP",
//                 data: [
//                     [
//                         152.419357,
//                         47.77,
//                         1624890073035
//                     ],
//                     [
//                         90.725807,
//                         47.94,
//                         1624890074025
//                     ],
//                     [
//                         124.423965,
//                         48.19,
//                         1624890075056
//                     ],
//                     [
//                         111.290321,
//                         48.39,
//                         1624890076026
//                     ],
//                     [
//                         133.548386,
//                         48.59,
//                         1624890077031
//                     ],
//                     [
//                         116.129036,
//                         48.77,
//                         1624890078024
//                     ],
//                     [
//                         156.7742,
//                         48.97,
//                         1624890079025
//                     ],
//                     [
//                         127.741936,
//                         49.17,
//                         1624890080028
//                     ],
//                     [
//                         120.276497,
//                         49.36,
//                         1624890081030
//                     ],
//                     [
//                         101.6129,
//                         49.59,
//                         1624890082028
//                     ],
//                     [
//                         125.80645,
//                         49.76,
//                         1624890083028
//                     ],
//                     [
//                         133.548386,
//                         49.94,
//                         1624890084025
//                     ],
//                     [
//                         128.571426,
//                         50.19,
//                         1624890089025
//                     ],
//                     [
//                         110.322578,
//                         50.38,
//                         1624890090028
//                     ],
//                     [
//                         130.645157,
//                         50.57,
//                         1624890095021
//                     ],
//                     [
//                         139.354843,
//                         50.76,
//                         1624890098025
//                     ],
//                     [
//                         150.967743,
//                         50.97,
//                         1624890103063
//                     ],
//                     [
//                         156.7742,
//                         51.19,
//                         1624890114019
//                     ],
//                     [
//                         139.354843,
//                         51.39,
//                         1624890115019
//                     ],
//                     [
//                         174.193542,
//                         51.56,
//                         1624890117021
//                     ],
//                     [
//                         127.741936,
//                         51.79,
//                         1624890122027
//                     ],
//                     [
//                         150.967743,
//                         51.99,
//                         1624890127052
//                     ],
//                     [
//                         195.967743,
//                         52.16,
//                         1624890131021
//                     ],
//                     [
//                         152.419357,
//                         52.35,
//                         1624890135020
//                     ],
//                     [
//                         168.3871,
//                         52.56,
//                         1624890140024
//                     ],
//                     [
//                         145.161285,
//                         52.75,
//                         1624890144021
//                     ],
//                     [
//                         130.645157,
//                         52.98,
//                         1624890150047
//                     ],
//                     [
//                         111.981567,
//                         53.19,
//                         1624890157018
//                     ],
//                     [
//                         166.935486,
//                         53.35,
//                         1624890161015
//                     ],
//                     [
//                         79.838707,
//                         53.55,
//                         1624890169027
//                     ],
//                     [
//                         156.7742,
//                         53.79,
//                         1624890174012
//                     ],
//                     [
//                         174.193542,
//                         53.94,
//                         1624890178055
//                     ]
//                 ]
//             }
//         ]
//     }
// ];
