import { getUrl } from '../utils';
import options from './options';
import {
  AreaDetailType,
  AreaFacilityDetails,
  AreaFormBackType,
  AreaFormType,
  CreateAreaBodyType,
  CreateAreaResponseType,
  SubdivisionFormType
} from './types';
import request from '../../utils/http/request';
import { WellStatus } from '../well/types';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getAreasFormApi = async (): Promise<AreaFormBackType[]> => request.get(url(''));

export const getAreaDetail = async (status: WellStatus[]): Promise<AreaDetailType> =>
  request.post(url('areaDetail'), { wellStatusType: status });

export const getAreaFacilityApi = async (id: string, status: WellStatus[] | string[]): Promise<AreaFacilityDetails[]> => {
  const bodies = id.split('&&');
  const body = {
    площадь_тип: bodies[0],
    площадь_ид: bodies[1],
    well_status_type: status
  };
  return request.post(url('areaFacilityDetailMnemonics'), body);
};

export const createAreaApi = async (data: CreateAreaBodyType): Promise<CreateAreaResponseType> =>
  request.post(url(''), data);

export const createAreaLinkedToFieldApi = async (data: SubdivisionFormType) => request.post('/api/dd/area-field', data);
