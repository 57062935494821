import { JSX, useRef } from 'react';

import { Toast } from 'primereact/toast';

import ToastContext from 'contexts/toast';

export default ({ life, children }: { life: number, children: JSX.Element }): JSX.Element => {
  const toast = useRef(null);

  return (
    <ToastContext.Provider value={{ toast, life }}>
      <Toast ref={toast} style={{ top: 70 }} />
      {children}
    </ToastContext.Provider>
  );
};
