import { AxiosResponse } from 'axios';
import { ChartLine } from '../../sdk/lineChart/types';

export type trajectoryPointType = {
    x: number[]
    y: number[]
    z: number[]
};

export type WellBoreTrackType = {
    points: trajectoryPointType
    wellboreName: string
    wellboreUid: string
    wellName: string
    wellUid: string
    xoffsetWell?: number
    yoffsetWell?: number
};

export interface ITrajectoryBase {
    uid?: string
    stationMd: number | string
    inclination: number
    azimuth: number | string
}

export interface ITrajectoryTableData extends ITrajectoryBase {
    stationTvd: number
    dogLegSeverity: number | string
    offsetFromMouth: number | string
    absoluteMark: number | string
    trajectoryId: string
}

export interface IMaxTvdMd {
    tvd: number,
    md: number
}

// Intersection

export interface ITrajectoryCalcBack {
    item_uuid: string;
    profile_calc_uuid: string;
    anticollision_calc_uuid: string;
    item_meta: null | object;
    item_data: null | object;
    row_changed_by: null;
    row_changed_datetime: string;
    row_created_by: null;
    row_created_datetime: string;
    well_uuid: string;
}

export interface ITrajectoryCalc {
    itemUuid: string;
    itemMeta: null | object;
    itemData: null | object;
    rowChangedBy: null;
    rowChangedDatetime: string;
    rowCreatedBy: null;
    rowCreatedDatetime: string;
    wellUuid: string;
    profileCalcUuid: string;
    anticollisionCalcUuid: string;
}

export interface ITrajectoryWellBack {
    item_uuid: string;
    survey_tool_uuids: string[];
    item_meta: null | object;
    item_data: null | object;
    row_changed_by: null;
    row_changed_datetime: string;
    row_created_by: null;
    row_created_datetime: string;
    type: null | 'plan' | 'target';
}

export interface ITrajectoryWell {
    itemUuid: string;
    surveyToolUuids: string[];
    itemMeta: null | object;
    itemData: null | object;
    rowChangedBy: null;
    rowChangedDatetime: string,
    rowCreatedBy: null;
    rowCreatedDatetime: string;
    type: null | 'plan' | 'target';
}

export interface ISurveyToolBack {
    item_uuid: string;
    item_meta: null | object;
    item_data: null | object;
    row_changed_by: null;
    row_changed_datetime: string;
    row_created_by: null;
    row_created_datetime: string;
    name: string;
    description: string;
    error_model: 'iscwsa_tool' | 'se_tool' | 'ce_tool';
}

export interface ISurveyTool {
    itemUuid: string;
    itemMeta: null | object;
    itemData: null | object;
    rowChangedBy: null;
    rowChangedDatetime: string;
    rowCreatedBy: null;
    rowCreatedDatetime: string;
    name: string;
    description: string;
    errorModel: 'iscwsa_tool' | 'se_tool' | 'ce_tool';
}

export interface IAnticollisionCalcBack {
    item_uuid: string;
    offset_wells_uuids: string[];
    item_meta: null | object;
    item_data: null | object;
    row_changed_by: null;
    row_changed_datetime: string;
    row_created_by: null;
    row_created_datetime: string;
    sigma: number;
    interp_md_step: number;
    error_surface_type: string;
    max_min_dist: number;
    max_sf: number;
    max_ellipse_dist: number;
    linked_with_trajectory_calc: string;
}

export interface IAnticollisionCalc {
    itemUuid: string;
    wellsUuids: string[];
    itemMeta: null | object;
    itemData: null | object;
    rowChangedBy: null;
    rowChangedDatetime: string;
    rowCreatedBy: null;
    rowCreatedDatetime: string;
    sigma: number;
    interpMdStep: number;
    errorSurfaceType: string;
    maxMinDist: number;
    maxSf: number;
    maxEllipseDist: number;
    linkedWithTrajectoryCalc: string
}

export interface ICalculationOffset {
    well_uuid: string;
    md: number[];
    incl: number[];
    azim: number[];
    northing: number[];
    easting: number[];
    tvd: number[];
    tvdss: number[];
    sf: number[];
    tfo: number[];
    min_dist: number[];
    ellipse_dist: number[];
    displacement: number[];
    x: number[];
    y: number[];
    z: number[];
}

export interface ICalculationTarget {
    well_uuid: string,
    md: number[],
    incl: number[],
    azim: number[]
    northing: number[]
    easting: number[];
    tvd: number[];
    tvdss: number[];
    // смещение от устья
    displacement: number[];
}

export interface IAnticollisionCalculation {
    result: {
        trajectory_calc_uuid: string;
        sigma: number;
        interp_md_step: number;
        error_surface_type: string;
        max_min_dist: null;
        max_sf: null;
        max_ellipse_dist: null;
        result_by_offset: ICalculationOffset[];
        target_well: ICalculationTarget;
    };
    errors: string[];
}

export interface IGeneralizedResult {
    targetWellName: string;
    offsetWellName: string;
    targetMd: string;
    offsetMd: string;
    minDist: string;
    ellipseDist: string;
    tfo: string;
    sfFixed: string;
    sf: number;
}

export interface IDetailTargetData {
    md: string;
    tvdss: string;
    tvd: string;
    incl: string;
    azim: string;
    northing: string;
    easting: string;
    index: number;
}

export interface IFlatApproachData {
    wellName: string;
    // по стволу
    md: string;
    // Глубина по вертикали (море)
    tvdss: string;
    // Глубина по вертикали
    tvd: string;
    // Зенитный угол
    incl: string;
    // Азимут дирекц
    azim: string;
    // Смещение на север
    northing: string;
    // Смещение на восток
    easting: string;
    // Между центрами
    minDist: string;
    // Между эллипсами
    ellipseDist: string;
    // Фактор сближения
    sf: string;
    // Направление
    tfo: string;
}

export type SfGraphType = {
    offsetLines: ChartLine[],
    maxMd: number;
};

// График расстояние между центрами
export type MinDistGraphType = {
    offsetLines: ChartLine[];
};

// График Расстояние между эллипсами
export type EllipseDistGraphType = {
    offsetLines: ChartLine[];
};

// Forecast

export type CalcSurveyType = {
    itemUuid: string;
    md: number;
    mdDelta: null | number;
    incl: number;
    azim: number;
    tvd: number;
    tvdss: null | number;
    northing: number;
    easting: number | null;
    totalDisp: null | number;
    totalAzim: null | number;
    dls: number;
    tfo: null | number;
    buildRate: null | number;
    turnRate: null | number;
};

export type CalcSurveyBackType = {
    item_uuid: string;
    md: number;
    md_delta: null | number;
    incl: number;
    azim: number;
    tvd: number;
    tvdss: null | number;
    northing: number;
    easting: number | null;
    total_disp: null | number;
    total_azim: null | number;
    dls: number;
    tfo: null | number;
    build_rate: null | number;
    turn_rate: null | number;
};

// j
// s
// nudge_md_incl_azim
// nudge_tvd_incl_azim
// nudge_dls_incl_azim
// nudge_dls_tvd_azim
// nudge_undefined

export enum CalcTypes {
    MdInclAzim = 'md_incl_azim',
    TvdInclAzim = 'tvd_incl_azim',
    DlsInclAzim = 'dls_incl_azim',
    DlsTvdAzim = 'dls_tvd_azim',
    Init = 'init',
    Undefined = 'undefined'
}
export enum ProfileTypes {
    J = 'j',
    S = 's',
    NudgeMdInclAzim = 'nudge_md_incl_azim',
    NudgeTvdInclAzim = 'nudge_tvd_incl_azim',
    NudgeDlsInclAzim = 'nudge_dls_incl_azim',
    NudgeDlsTvdAzim = 'nudge_dls_tvd_azim',
    NudgeUndefined = 'nudge_undefined',
    // by front
    Init = 'init',
    ForCreate = 'for_create',
    OptimumAlignTan = 'opt_align_chc_tangent',
    OptimumAlignTvd = 'opt_align_chc_tvd',
    OptimumAlignDls = 'opt_align_chc_dls'
}

export type TypedSurvey = CalcSurveyType & {
    profileType: ProfileTypes;
    calcId: string;
    isEditable?: boolean;
};

export type SegmentBackType = {
    profile_calc_uuid: string;
    profile_type: ProfileTypes;
    surveys: CalcSurveyBackType[];
};

export type SegmentType = {
    profileCalcUuid: string;
    profileType: ProfileTypes;
    surveys: CalcSurveyType[];
};

export interface IProfileCalcBack {
    item_uuid: string;
    well_uuid: string;
    segments: (SegmentBackType | CalcSurveyBackType)[];
    item_meta: null;
    item_data: null;
    row_changed_by: null;
    row_changed_datetime: string;
    row_created_by: null;
    row_created_datetime: string;
    md_step: number;
    linked_with_trajectory_calc: string;
}

export interface IProfileCalc {
    itemUuid: string;
    wellUuid: string;
    segments: (SegmentType | CalcSurveyType)[];
    itemMeta: null;
    itemData: null;
    rowChangedBy: null;
    rowChangedDatetime: string;
    rowCreatedBy: null;
    rowCreatedDatetime: string;
    mdStep: number;
    linkedWithTrajectoryCalc: string;
}

export interface ItrajectoryItemDataType {
    color: string;
    wellName: string;
    md: string;
    inc: string;
    az: string;
    minDist?: string | null
}

export type NudgeCalcBackType = {
    item_uuid: string;
    surveys: CalcSurveyBackType[];
    item_meta: null;
    item_data: null;
    row_changed_by: null;
    row_changed_datetime: string;
    row_created_by: null;
    row_created_datetime: string;
    calc_type: CalcTypes;
    md: number | null;
    md_len: number | null;
    is_md_len_checked: boolean;
    incl: number | null;
    azim: number | null;
    tvd: number | null;
    dls: number | null;
    is_auto_md: boolean;
    is_auto_md_len: boolean;
    is_auto_incl: boolean;
    is_auto_azim: boolean;
    is_auto_tvd: boolean;
    is_auto_dls: boolean;
    linked_with_profile_calc: string;
    profile_type: ProfileTypes;
};

export type NudgeCalcType = {
    itemUuid: string;
    surveys: CalcSurveyType[]
    itemMeta: null | object;
    itemData: null | object;
    rowChangedBy: null;
    rowChangedDatetime: string;
    rowCreatedBy: null;
    rowCreatedDatetime: string;
    md: number | null;
    mdLen: number | null;
    incl: number | null;
    azim: number | null;
    calcType: CalcTypes;
    tvd: number | null;
    profileType: ProfileTypes;
    dls: number | null;
    linkedWithProfileCalc: string;
    isAutoMd: boolean;
    isAutoMdLen: boolean;
    isAutoIncl: boolean;
    isAutoAzim: boolean;
    isAutoTvd: boolean;
    isAutoDls: boolean;
};

export type JProfileCalcType = {
    itemUuid: string;
    surveys: CalcSurveyType[];
    itemMeta: null | object;
    itemData: null | object;
    rowChangedBy: string;
    rowChangedDatetime: string;
    rowCreatedBy: string;
    rowCreatedDatetime: string;
    tvd: number | null;
    northing: number | null;
    easting: number | null;
    displacement: number | null;
    azim: number | null;
    holdLenVert: number | null;
    isAutoHoldLenVert: boolean;
    holdLenSlant: number | null;
    isAutoHoldLenSlant: boolean;
    buildRate: number | null;
    isAutoBuildRate: boolean;
    maxIncl: number | null;
    isAutoMaxIncl: boolean;
    linkedWithProfileCalc: string;
    linkedWithTarget: string | null;
};

export type JProfileCalcBackType = {
    item_uuid: string;
    surveys: CalcSurveyBackType[];
    item_meta: null | object;
    item_data: null | object;
    row_changed_by: string;
    row_changed_datetime: string;
    row_created_by: string;
    row_created_datetime: string;
    tvd: number | null;
    northing: number | null;
    easting: number | null;
    displacement: number | null;
    azim: number | null;
    hold_len_vert: number | null;
    is_auto_hold_len_vert: boolean;
    hold_len_slant: number | null;
    is_auto_hold_len_slant: boolean;
    build_rate: number | null;
    is_auto_build_rate: boolean;
    max_incl: number | null;
    is_auto_max_incl: boolean;
    linked_with_profile_calc: string;
    linked_with_target: string | null;
};

export type SProfileCalcType = {
    itemUuid: string;
    surveys: CalcSurveyType[];
    itemMeta: null | object;
    itemData: null | object;
    rowChangedBy: string;
    rowChangedDatetime: string;
    rowCreatedBy: string;
    rowCreatedDatetime: string;
    tvd: number | null;
    northing: number | null;
    easting: number | null;
    displacement: number | null;
    azim: number | null;
    holdLenVert: number | null;
    isAutoHoldLenVert: boolean;
    buildRate1: number | null;
    isAutoBuildRate1: boolean;
    maxIncl1: number | null;
    isAutoMaxIncl1: boolean;
    stabLen1: number | null;
    isAutoStabLen1: boolean;
    buildRate2: number | null;
    isAutoBuildRate2: boolean;
    maxIncl2: number | null;
    isAutoMaxIncl2: boolean;
    stabLen2: number | null;
    isAutoStabLen2: boolean;
    linkedWithProfileCalc: string;
    linkedWithTarget: string;

};

export type SProfileCalcBackType = {
    item_uuid: string;
    surveys: CalcSurveyBackType[];
    item_meta: null | object;
    item_data: null | object;
    row_changed_by: string;
    row_changed_datetime: string;
    row_created_by: string;
    row_created_datetime: string;
    tvd: number | null;
    northing: number | null;
    easting: number | null;
    displacement: number | null;
    azim: number | null;
    hold_len_vert: number | null;
    is_auto_hold_len_vert: boolean;
    build_rate_1: number | null;
    is_auto_build_rate_1: boolean;
    max_incl_1: number | null;
    is_auto_max_incl_1: boolean;
    stab_len_1: number | null;
    is_auto_stab_len_1: boolean;
    build_rate_2: number | null;
    is_auto_build_rate_2: boolean;
    max_incl_2: number | null;
    is_auto_max_incl_2: boolean;
    stab_len_2: number | null;
    is_auto_stab_len_2: boolean;
    linked_with_profile_calc: string;
    linked_with_target: string;
};
export type OptimumAlignTypes = 'tvd' | 'dls' | 'tangent';

export type OptimumAlignBackType = {
    item_uuid: string;
    surveys: CalcSurveyBackType[];
    item_meta: null;
    item_data: null;
    row_changed_by: null;
    row_changed_datetime: string;
    row_created_by: null;
    row_created_datetime: string;
    tvd: number | null;
    northing: number | null;
    easting: number | null;
    displacement: number | null;
    dls_1: number | null;
    dls_2: number | null;
    tvd_1: number | null;
    tvd_2: number | null;
    tangent_length: number | null;
    incl: number | null;
    azim: number | null;
    calc_type: OptimumAlignTypes;
    linked_with_profile_calc: string;
    linked_with_target: null;
    profile_type: 'opt_align_chc_tangent';
};

export type OptimumAlignType = {
    itemUuid: string;
    surveys: CalcSurveyType[];
    itemMeta: null;
    itemData: null;
    rowChangedBy: null;
    rowChangedDatetime: string;
    rowCreatedBy: null;
    rowCreatedDatetime: string;
    tvd: number | null;
    northing: number | null;
    easting: number | null;
    displacement: number | null;
    dls1: number | null;
    dls2: number | null;
    tvd1: number | null;
    tvd2: number | null;
    tangentLength: number | null;
    incl: number | null;
    azim: number | null;
    calcType: OptimumAlignTypes;
    linkedWithProfileCalc: string;
    linkedWithTarget: null;
    profileType: 'opt_align_chc_tangent';
};

export type ReportType = {
    report: AxiosResponse<Blob>,
    well_uuid: string
};

export type ResultPlanType = {
    md: number[];
    incl: number[];
    azim: number[];
    northing: number[];
    easting: number[];
    tvd: number[];
};

export type ResultFactType = {
    md: number[];
    incl: number[];
    azim: number[];
    northing: number[];
    easting: number[];
    tvd: number[];
    abDisp: number[];
    lrDisp: number[];
};

export type ResultFactBackType = {
    md: number[];
    incl: number[];
    azim: number[];
    northing: number[];
    easting: number[];
    tvd: number[];
    ab_disp: number[];
    lr_disp: number[];
};

export type FpAnalysisResultType = {
    wellUuid: string;
    planResult: ResultPlanType;
    factResult: ResultFactType;
};

export type FpAnalysisResultBackType = {
    well_uuid: string;
    plan_result: ResultPlanType;
    fact_result: ResultFactBackType;
};

export type FpAnalysisBackType = {
    item_uuid: string;
    result: FpAnalysisResultBackType;
    item_meta: null | object;
    item_data: null | object;
    row_changed_by: string;
    row_changed_datetime: string;
    row_created_by: string;
    row_created_datetime: string;
    linked_with_plan: string;
    linked_with_well: string;
    linked_with_fact: string;
};

export type FpAnalysisType = {
    itemUuid: string;
    result: FpAnalysisResultType;
    itemMeta: null | object;
    itemData: null | object;
    rowChangedBy: string;
    rowChangedDatetime: string;
    rowCreatedBy: string;
    rowCreatedDatetime: string;
    linkedWithPlan: string;
    linkedWithWell: string;
    linkedWithFact: string;
};

export type AnalysisDataType = {
    planMd: number;
    planIncl: number;
    planAzim: number;
    factMd: number;
    factIncl: number;
    factAzim: number;
    abDisp: number;
    lrDisp: number;
};

export type AnalysisGraphType = {
    points: {
        x: number[];
        y: number[];
        z: number[];
    }
    wellName: string;
    wellUid: string;
    wellboreName: string;
    wellboreUid: string;
};

export type NudgeCalcCreateType = Omit<NudgeCalcType,
    'itemUuid' | 'rowChangedBy' | 'rowChangedDatetime' | 'rowCreatedBy' | 'rowCreatedDatetime' | 'surveys'>;

export type NudgeCalcCreateBackType = Omit<NudgeCalcBackType,
    'item_uuid' | 'row_created_by' | 'row_created_datetime' | 'row_changed_by' | 'row_changed_datetime' | 'surveys' | 'is_auto_dls' | 'is_auto_md' | 'is_auto_md_len' | 'is_auto_tvd' | 'is_auto_azim' | 'is_auto_incl'>;

export type JProfileCalcCreateType = Omit<JProfileCalcType,
    'itemUuid' | 'rowChangedBy' | 'rowChangedDatetime' | 'rowCreatedBy' | 'rowCreatedDatetime' | 'surveys'>;

export type JProfileCalcCreateBackType = Omit<JProfileCalcBackType,
    'item_uuid' | 'row_created_by' | 'row_created_datetime' | 'row_changed_by' | 'row_changed_datetime' | 'surveys'>;

export type SProfileCalcCreateType = Omit<SProfileCalcType,
    'itemUuid' | 'rowChangedBy' | 'rowChangedDatetime' | 'rowCreatedBy' | 'rowCreatedDatetime' | 'surveys'>;

export type SProfileCalcCreateBackType = Omit<SProfileCalcBackType,
    'item_uuid' | 'row_created_by' | 'row_created_datetime' | 'row_changed_by' | 'row_changed_datetime' | 'surveys'>;

export type OptimumAlignCreateBackType = Omit<OptimumAlignBackType, 'profile_type' | 'row_created_by' | 'surveys' | 'row_created_datetime' | 'row_changed_datetime' | 'row_changed_by' | 'item_uuid'>;