import { ReactNode, useEffect } from 'react';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import ApiContext from 'contexts/api';
import { registerHttpClient } from './request';
import HttpClient from './http-client';
import { useToast } from '../hooks';
import { getTokenString } from './utils';
import { useAppDispatch } from '../../store/hooks';
import { checkAuth } from '../../models/auth/actions';

export default ({ children }: { children: ReactNode }) => {
  const { showToast } = useToast();
  const dispatch = useAppDispatch();

  const getTraceId = (headers: any) => headers.traceid || null;

  useEffect(() => {
    HttpClient.instance.interceptors.request.use((config: AxiosRequestConfig) => {
      config.headers.Authorization = getTokenString();
      return config;
    }, (error: any) => {
      showToast.error(error.message);
      return error;
    });

    HttpClient.instance.interceptors.response.use((config: AxiosResponse) => {
      // success traceId
      console.log('traceId:', `${getTraceId(config.headers)} (${config.config?.url || ''})`);
      return config;
    }, async (error: AxiosError & { config: { isRetry: boolean } }) => {
      if (error.response) {
        const e = error.response;
        console.error('OMG error! (id:', getTraceId(e.headers), ')');
        const originalRequest = error.config;
        switch (true) {
          case (e.status === 401 && !error.config.isRetry): {
            originalRequest.isRetry = true;
            await dispatch(checkAuth());
            return HttpClient.instance.request(originalRequest);
          }
          case !!(e.data && e.data.message): {
            // todo added url in toast here
            const message = e.data.comment === 'Ошибка обращения к ППДМ АПИ'
              ? 'нет проектных данных проектной глубины' : e.data.message;
            const messageURL = e.data.comment === 'Ошибка обращения к ППДМ АПИ' ? '' : error.config.url;
            showToast.error(`${message} ${messageURL}`);
            break;
          }
          case (e.status === 403): {
            showToast.error('Нет доступа для данной операции');
            break;
          }
          case !!(e.data.detail): {
            showToast.error(e.data.detail);
            break;
          }
          default: {
            showToast.error('Неизвестная ошибка');
          }
        }
      }
      throw error;
    });

    registerHttpClient(HttpClient);
    // eslint-disable-next-line
  }, [])

  return (
    <ApiContext.Provider value={{}}>
      {children}
    </ApiContext.Provider>
  );
};
