import options from './options';
import { getUrl } from '../utils';
import request from '../../utils/http/request';
import { DrillingOperationStatusType } from './types';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getProjectDataApi = async (wellId: string): Promise<DrillingOperationStatusType[]> =>
  request.get(url(`getDrillingOperationStatusListByMnemonicsAll/${wellId}`));

export const getDrillingDuration = async (wellId: string): Promise<DrillingOperationStatusType[]> =>
  request.get(url(`getDrillingOperationStatusListTimeDurationByMnemonic/${wellId}`));