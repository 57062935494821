import { JSX } from 'react';

export const createContent = (title: string, message: string = ''): JSX.Element => {
  if (title) {
    return (
      <>
        <span className="p-message-icon pi  pi-info-circle" />
        <div className="title-wrap">
          <p className="p-message-summary">{title}</p>
          {message && <p className="p-message-detail">{message}</p>}
        </div>
      </>
    );
  }

  return (
    <>
      <span className="p-message-icon pi pi-info-circle" />
      <p className="p-message-detail">{message}</p>
    </>
  );
};