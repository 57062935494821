import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import { SystemProvider } from './utils/system-provider';
import { setupStore } from './store';
import './utils/i18n';

// переменные цб - оставить
// import './styles/dd-variables.scss';

// стили и иконки из общей репы гтм - оставить (primeflex 3.2.0 там внутри)
// import '@innouni/ui-kit-styles/outputStyles/elementStyles.css';
// import '@innouni/ui-kit-styles/outputStyles/spacingStyles.css';
// import '@innouni/ui-kit-styles/outputStyles/icons/primeicons.css';

// рестайлы цб и праймфлекс - не будет
import './styles/styles.scss';
import 'primeicons/primeicons.css';
import './styles/primeflex/primeflex.scss';

// иконки цб - оставить
import '../public/static/fonts/dd-icon-font/dd-icon-font.css';
import { router } from './router';

const store = setupStore();
const root = createRoot(document.getElementById('root')!);

root.render(
  <Provider store={store}>
    <SystemProvider>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </SystemProvider>
  </Provider>
);
