import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStartTemplate, updateActiveCurveInTracks } from '../../pages/monitoring/pages/well/forms/well-tablet/utils';
import { getMnemonicNames } from 'models/well-log/actions';
import { chunk } from 'lodash';

export type ChartElement = {
  code: string;
  name: string;
  lineWidth?: number;
  color?: string;
  lineType?: string;
  glave?: number[][]; // glave[0] min, glave[1] max
  inverted?: boolean;
  title?: string,
  logarithmic?: boolean
};

export type TrackStateElement = {
  charts: ChartElement[],
  gridIntervalCount?: number;
  title?: string;
  logarithmic?: boolean;
};

export type PrevActive = {
  code: string;
  name: string;
  prevLineWidth: number | undefined;
};

export type TabletConfigStateType = {
  trackState: TrackStateElement[],
  templateValue: string,
  activeCurve: string | null
  refresh: boolean,
  activeRaw: TabletRawType | null,
  prevActiveCurve: null | PrevActive
};

export type TabletRawType = {
  name: string,
  stroke: string,
  code: string,
  color: string,
  glave: number[][],
  title: string,
  lineType: string,
  inverted: boolean,
  logarithmic: boolean
};

const initialState: TabletConfigStateType = {
  trackState: getStartTemplate('GTI'),
  templateValue: 'GTI',
  activeCurve: null,
  refresh: true,
  activeRaw: null,
  prevActiveCurve: null
};

export const tabletTracksSlice = createSlice({
  name: 'tabletTracks',
  initialState,
  reducers: {
    changeTrackSchema(state, action: PayloadAction<string>) {
      state.trackState = getStartTemplate(action.payload);
      state.templateValue = action.payload;
    },
    updateTracks(state, action: PayloadAction<{ value: any, trackIndex: number }>) {
      const { value, trackIndex } = action.payload;
      state.trackState[trackIndex].charts = value;
    },
    addTrack(state) {
      state.trackState = [...state.trackState, { charts: [] }];
    },
    resetTracks(state) {
      state.trackState = getStartTemplate(state.templateValue);
    },
    removeTrack(state, action: PayloadAction<number>) {
      state.trackState.splice(action.payload, 1);
    },
    updateActiveCurve(state, action: PayloadAction<string | null>) {
      const currentValue = state.activeCurve;
      const newValue = currentValue !== action.payload ? action.payload : null;
      state.activeCurve = newValue;
      updateActiveCurveInTracks(state, newValue);
    },
    deleteActiveCurve(state, action: PayloadAction<string>) {
      state.trackState
        .forEach((track: TrackStateElement) => {
          const index = track.charts.findIndex((item: any) => item.code === action.payload);
          if (index > -1) {
            track.charts.splice(index, 1);
          }
        });
      state.activeCurve = null;
    },
    updateRefresh(state, action: PayloadAction<boolean>) {
      state.refresh = action.payload;
    },
    setActiveRaw(state: TabletConfigStateType, { payload }: PayloadAction<TabletRawType | null>) {
      state.activeRaw = payload;
    },
    resetAll(state: TabletConfigStateType) {
      Object.keys(state).forEach(key => {
        // @ts-ignore
        state[key] = initialState[key];
      });
    }
  },
  extraReducers: {
    [getMnemonicNames.pending.type]: (state) => {

    },
    [getMnemonicNames.fulfilled.type]: (state, action: PayloadAction<TrackStateElement[]>) => {
      let charts: any[] = []
      chunk(action.payload, 4).map((item) => {
        charts.push({ charts: item })
      })
      // state.trackState = charts;
      // state.error = '';
    },
    [getMnemonicNames.rejected.type]: (state, action: PayloadAction<string>) => {

    },
  }
});

export default tabletTracksSlice.reducer;
