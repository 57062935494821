import { IObject } from '../interfaces';

export const objectHasProperty = (obj: IObject, property: string): boolean =>
  Object.prototype.hasOwnProperty.call(obj, property);

/**
 * Преобразует id и version в ключ
 * @param id
 * @param version
 * @returns {string}
 */
export const getKey = (id: string, version: string): string => (`${id}_${version}`);

export const createId = (count: number = 1): string => {
  if (count > 1) {
    let id: string = 'id';
    for (let i = 0; i < count; i++) {
      id += `-${Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
        }`;
    }

    return id;
  }

  return `id-${Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
    }`;
};

/**
 * Возвращает id и version из ключа
 * @param key
 * @returns {void|*|string[]|[]} [id, version]
 */
export const getIdVersion = (key: string): string[] => (key.split('_'));

/**
 * Парсинг объекта по пути
 * @param object
 * @param path
 * @returns {*}
 */
export const getValueByObjectPath = (object: IObject, path = []): IObject => {
  if (!path.length) return object;
  path.forEach(
    (element, index) => {
      object = object[element] || (path.length - 1 === index ? undefined : {});
    }
  );
  return object;
};

/**
 * Проверяет Объект {} на пустоту
 * @param obj
 * @returns {boolean}
 */
export const isEmpty = (obj: IObject): boolean => {
  if (obj === null || obj === undefined) return true;
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 *
 */
export const isRequired = (): Error => {
  throw new Error('Required function parameter is empty');
};

const takeLatestStorage: IObject = {};
/**
 * Функция для оптимизации обработки многократных вызовов
 * @param id - идентификатор данной операции.
 * @param cb - callback выполнения операции
 * @param timeout - таймер операции (50 мс по умолчанию)
 * @returns {*}
 */
export const takeLatest = (id: string, cb: Function, timeout = 50): string => {
  const timerId = takeLatestStorage[id];
  if (timerId) {
    clearInterval(timerId);
  }
  takeLatestStorage[id] = setTimeout(() => {
    clearInterval(timerId);
    cb();
  }, timeout);
  return id;
};

export const removeEmptyValues = (rawObject: IObject): IObject => {
  const object: IObject = {};
  Object.keys(rawObject).forEach((key) => {
    const rawElement = rawObject[key];
    const elementType = typeof rawElement;
    switch (elementType) {
      case 'undefined': {
        break;
      }
      // case 'object': {
      //     if (rawElement == null) {
      //         break;
      //     }
      //     if (Array.isArray(rawElement)) {
      //         object[key] = rawElement;
      //     // } else {
      //     //     object[key] = removeEmptyValues(rawElement);
      //     }
      //     break;
      // }
      default: {
        object[key] = rawElement;
      }
    }
  });
  return object;
};

/**
 * Функция нахождения разницы между двумя объектами, без рекурсии
 * @param after
 * @param before
 * @param emptyValue
 * @returns {{}}
 */
export const getDiff = (after: IObject, before: IObject, emptyValue: any): IObject => {
  const beforeKeys = Object.keys(before);
  const afterKeys = Object.keys(after);
  const result: IObject = {};
  afterKeys.forEach((key) => {
    const afterValue = after[key];
    const beforeValue = before[key];
    if (beforeValue !== afterValue) {
      result[key] = afterValue;
    }
  });
  beforeKeys.forEach((key) => {
    const afterValue = after[key];
    if (afterValue === undefined) {
      result[key] = emptyValue;
    }
  });
  return result;
};

/**
 * Функция скачивания файла с заданием ему имени и расширения
 * Добавляется link на страницу с урлом для скачивания документа
 * В которой указывается имя файла.
 * После скачивания элемент удаляется
 * @param data - файл (blob)
 * @param filename - имя файла
 * @param ext - расширение
 */
export const downloadFile = (data: Blob, filename: string | undefined = 'file', ext: string) => {
  console.log(data);
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.${ext}`;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

// округление чисел
export const cutNumbers = (num: any, digits = 2) => {
  const number = Number(num);
  // eslint-disable-next-line no-restricted-properties
  const reduced = Math.pow(10, -digits);
  // если очень маленькое число - округляем до первой цифры
  return number && Math.abs(number) < reduced ? number.toPrecision(1)
    // eslint-disable-next-line no-restricted-properties
    : Math.round(number * (Math.pow(10, digits))) / Math.pow(10, digits);
};

const dataFilterValues = [
  null, undefined, '', -999.25, '-999.25', '-999,25'
];

// Отсекание некорректных значений
export const filterValues = (value: any, filterNegatives: boolean = true) => {
  if (filterNegatives && value < 0) return '-';
  let result = value;
  dataFilterValues.forEach(val => {
    if (value === val) result = '-';
  });
  return result;
};

// замена запятой на точку
export const replaceComma = (value: any) => {
  if (typeof value === 'string' || value instanceof String) {
    return value.replace(/[,.]/, '.');
  }
  return value;
};

/**
 * Возвращает отличия в объектах
 * @param objectOne
 * @param objectTwo
 * @return вернет значение объекта objectOne
 */
export const getDifferences = (objectOne: IObject, objectTwo: IObject) => Object.keys(objectOne)
  .reduce((reducer: IObject, key: string) => {
    const valueOne = objectOne[key];
    const valueTwo = objectTwo[key];
    if (valueOne !== valueTwo) {
      return { ...reducer, [key]: valueOne };
    }
    return reducer;
  }, {});

/**
 * Преобразование координаты в десятичное значение
 * @param degrees
 * @param minutes
 * @param seconds
 */
export const convertDegreesToDecimal =
  (degrees: number | null | undefined, minutes: number | null | undefined, seconds: number | null | undefined): number => {
    const sign = !degrees ? 1 : degrees < 0 ? -1 : 1;
    return (degrees || 0) + ((minutes || 0) / 60) * sign + ((seconds || 0) / 3600) * sign;
  };

/**
 * Преобразование координаты из десятичного значения в градусы, минуты, секунды
 * @param deg
 */
export const convertDecimalToDegrees = (deg: number) => {
  const sign = !deg ? 1 : deg < 0 ? -1 : 1;

  const absolute = Math.abs(deg);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.round((minutesNotTruncated - minutes) * 60);

  return {
    degrees: sign * degrees,
    minutes,
    seconds
  };
};

// проверка строки, валидный ли это JSON
export const isJsonString = (str: string) => {
  if (str === null || str === 'null') return false;

  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// проверка, успешный ли диспатч
export const isSuccessRequest = (res: any): boolean => res.meta.requestStatus === 'fulfilled';

export const isGreenTheme = (): boolean => process.env.REACT_APP_THEME === 'GREEN';

export const cutFIO = (value: string) => {
  const array = value.split(' ');
  if (array.length !== 3) return '';

  return `${array[0]} ${array[1][0]}. ${array[2][0]}.`;
};

export const minToStringTime = (mins: number | null, seconds?: number | undefined) => {
  if (mins === null) return null;

  const hours = parseInt(String(mins / 60), 10);
  const minutes = mins - (hours * 60);
  const additionalZero = hours < 10 ? 0 : '';
  const additionalMinZero = minutes < 10 ? 0 : '';
  let additionalSecond: string | number = '';
  if (seconds) {
    additionalSecond = seconds < 10 ? `:0${seconds}` : `:${seconds}`;
  }
  return `${additionalZero}${hours}:${additionalMinZero}${minutes.toFixed(2)}${additionalSecond}`;
};

export const getWellIdTemporary = (name: string, wellId: string): string => {
  switch (name.toLowerCase().trim()) {
    case 'тестовая скважина':
      return '40008b9a-5380-3cac-8e39-76bf7c08af5b';
    case 'скважина тест':
      return '40008b9a-5380-3cac-8e39-76bf7c08af5b';
    case '25Г'.toLowerCase():
      return 'd3157f2f-0212-380a-9d04-2c127522a2d5';
    case 'test well':
      return '40008b9a-5380-3cac-8e39-76bf7c08af5b';
    case 'wwv':
      return 'd3157f2f-0212-380a-9d04-2c127522a2d5';

    case 'тестовая':
      return '698d51a1-9d8a-321c-a581-499d7b701668';
    case '24Г'.toLowerCase():
      return 'c4ca4238-a0b9-3382-8dcc-509a6f75849b';
    case '31222':
      return '0768f03a-8da9-3947-9159-0b8af5a48a37';
    default:
      return wellId;
  }
};

export const getWellNameTemporary = (name: string): string => {
  switch (name.trim()) {
    case '11011':
      return 'тестовая';
    case '11012':
      return '24Г';
    case '11013':
      return '31222';
    default:
      return name;
  }
};