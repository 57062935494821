import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import simplify from 'utils/simplify-js/simplify';
import options from './options';
import {
  getDocumentationData,
  getDrillingDurationData
} from './actions';
import {
  documentationDataType,
  DrillingOperationStatusType
} from './types';
import { ChartLine } from '../../sdk/lineChart/types';
import { generateLine } from './convertor';

const { name } = options;

type DocumentationState = {
  isLoading: boolean;
  error: string;
  dateError: string;
  allPageLoading: boolean;
  drillingOperationChart: ChartLine[];
  documentationData: DrillingOperationStatusType[],
  reducedDocumentationData: DrillingOperationStatusType[],
  pieChartData: documentationDataType[]
};

const initialState: DocumentationState = {
  isLoading: false,
  error: '',
  dateError: '',
  allPageLoading: false,
  documentationData: [],
  drillingOperationChart: [],
  reducedDocumentationData: [],
  pieChartData: []
};

export const documentationSlice = createSlice({
  name,
  initialState,
  reducers: {

  },
  extraReducers: {
    [getDocumentationData.pending.type]: (state) => {
      state.isLoading = true;
      state.allPageLoading = true;
    },
    [getDocumentationData.fulfilled.type]: (state, { payload }: PayloadAction<DrillingOperationStatusType[]>) => {
      state.isLoading = false;
      state.dateError = '';
      const data = payload.map((item) => {
        item.beginDateTimeInclusive *= 1000;
        item.endDateTimeExclusive *= 1000;
        return item;
      }) || null;
      state.documentationData = data;
      const simplifyData = simplify(data, 1, true) as DrillingOperationStatusType[];
      state.reducedDocumentationData = simplifyData;
      state.drillingOperationChart = generateLine(simplifyData);
    },
    [getDocumentationData.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.dateError = action.payload;
    },

    [getDrillingDurationData.pending.type]: (state) => {
      state.isLoading = true;
      state.allPageLoading = true;
    },
    [getDrillingDurationData.fulfilled.type]: (state, { payload }: PayloadAction<documentationDataType[]>) => {
      state.isLoading = false;
      state.dateError = '';
      state.pieChartData = payload || null;
    },
    [getDrillingDurationData.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.dateError = action.payload;
    }
  }
});

export default documentationSlice.reducer;
