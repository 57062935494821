import React, { PropsWithChildren, ReactNode } from 'react';
import Loader from 'components/loader';
import { useClassName } from '../../utils/cn';
import './style.scss';

type LoadingOverlayPropsType = {
  loading: boolean;
  comment?: string;
};

export const LoadingOverlay: React.FC<PropsWithChildren<LoadingOverlayPropsType>>
  = ({ loading, comment = '', children }) => {
    const cn = useClassName('loading-overlay');
    return (
      <>
        {children}
        {loading && (
          <div className={cn('panel-overlay')}>
            <Loader comment={comment} />
          </div>
        )}
      </>
    );
  };
