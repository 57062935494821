import { IPagingSearch } from 'interfaces';
import { getTokenString } from 'utils/http/utils';
import { AxiosResponse } from 'axios';
import {
  FpAnalysisBackType,
  IAnticollisionCalc,
  IAnticollisionCalcBack,
  IAnticollisionCalculation,
  IMaxTvdMd,
  IProfileCalc,
  ISurveyTool,
  ISurveyToolBack,
  ITrajectoryBase,
  ITrajectoryCalc,
  ITrajectoryCalcBack,
  ITrajectoryTableData,
  ITrajectoryWell,
  ITrajectoryWellBack,
  JProfileCalcBackType,
  JProfileCalcCreateBackType,
  NudgeCalcBackType,
  NudgeCalcCreateBackType,
  OptimumAlignBackType,
  OptimumAlignCreateBackType,
  SProfileCalcBackType,
  SProfileCalcCreateBackType,
  WellBoreTrackType
} from './types';
import { getUrl } from '../utils';
import options from './options';
import request from '../../utils/http/request';
import {
  anticollisionCalcToFront,
  toolToFront,
  trajectoryCalcToFront,
  trajectoryWellToFront
} from './converters';

const {
  apiUrl,
  proxyCalcUrl,
  ddProxyUrl,
  calcTrajectoryUrl
} = options;

const ddApiUrl = getUrl(apiUrl);
const proxyCalcApi = getUrl(proxyCalcUrl);
const ddProxyApi = getUrl(ddProxyUrl);
const calcTrajectoryApi = getUrl(calcTrajectoryUrl);
// const calcApi = (url: string) => `http://10.100.8.65:9101/calc_trajectory/${url}`;

// Плановые траектории
export const getTrajectoryTracksByWellIdsApi = async (
  wellboreUids: string[]
): Promise<WellBoreTrackType[]> =>
  request.post(ddApiUrl('computeCoordinates'), wellboreUids);

// Вычислить координаты фактических тракторий
export const getTrajectoryTracksFactByWellIdsApi = async (
  wellboreUids: string[]
): Promise<WellBoreTrackType[]> =>
  request.post(ddApiUrl('computeCoordinates/fact'), wellboreUids);

// Получить информацию по точкам плановой траектории для ствола
export const getTrajectoryTableDataByWellboreApi = async (
  wellbore: string,
  page: number,
  size: number
): Promise<IPagingSearch<ITrajectoryTableData>> =>
  request.get(ddApiUrl(`${wellbore}?page=${page}&size=0`));

/**
 * Получить максимальные значения TVD и MD по wellboreUid
 * @param uid uid скважины
 */
export const getMdByWellbore = async (uid: string): Promise<IMaxTvdMd> =>
  request.post(ddApiUrl(`getMdByWellboreId/${uid}`));

// Получить информацию по точкам фактической траектории для ствола
export const getTrajectoryFactTableDataByWellboreApi = async (
  wellbore: string,
  page: number,
  size: number
): Promise<IPagingSearch<ITrajectoryTableData>> =>
  request.get(ddApiUrl(`fact/${wellbore}?page=${page}&size=0`));

// Создать или перезаписать плановую траекторию
export const createOrUpdateTrajectoryPlanApi = async (
  pointsData: ITrajectoryBase[],
  wellboreUid: string
): Promise<ITrajectoryTableData[]> =>
  request.post(ddApiUrl('createOrUpdate'), {
    type: 'PLAN',
    wellboreUid,
    content: pointsData
  });

// Создать или перезаписать фактическую траекторию
export const createOrUpdateTrajectoryFactApi = async (
  pointsData: ITrajectoryBase[],
  wellboreUid: string
): Promise<ITrajectoryTableData[]> =>
  request.post(ddApiUrl('createOrUpdate/fact'), {
    type: 'FACT',
    wellboreUid,
    content: pointsData
  });

// Проверить есть ли плановые траектории by wellboreUid
export const checkTrajectoryPointsApi = async (
  wellboreUid: string,
): Promise<ITrajectoryTableData[]> =>
  request.get(ddApiUrl(`${wellboreUid}/check`));

// Проверить есть ли фактические траектории by wellboreUid
export const checkTrajectoryFactPointsApi = async (
  wellboreUid: string,
): Promise<ITrajectoryTableData[]> =>
  request.get(ddApiUrl(`fact/${wellboreUid}/check`));

export const updateTrajectoryTableItemApi = async (
  uid: string,
  data: Partial<ITrajectoryBase>
): Promise<ITrajectoryTableData> =>
  request.post(ddApiUrl(`unit/${uid}`), data);

// Копирование из плана в факт
export const trajectoryCopy = async (wellboreUid: string) => request.post(ddApiUrl(`copyPlanToFact/${wellboreUid}`));

// Удалить плановую точку
export const deletePlanTrajectoryTableItemApi = async (
  itemUid: string
): Promise<string> => {
  await request.delete(ddApiUrl(`/unit/${itemUid}`));
  return itemUid;
};

// Удалить фактическую точку
export const deleteFactTrajectoryTableItemApi = async (
  itemUid: string
): Promise<string> => {
  await request.delete(ddApiUrl(`fact/unit/${itemUid}`));
  return itemUid;
};
// Удалить все плановые трактории точки для ствола
export const deleteAllTrajectoryBywellboreUid = async (
  wellboreUid: string
): Promise<string> => {
  await request.delete(ddApiUrl(`${wellboreUid}`));
  return wellboreUid;
};
// Удалить все плановые трактории точки для ствола
export const deleteAllTrajectoryFactBywellboreUid = async (
  wellboreUid: string
): Promise<string> => {
  await request.delete(ddApiUrl(`fact/${wellboreUid}`));
  return wellboreUid;
};

const mockTrajectoryData = [
  {
    id: 5,
    md: 140,
    inclination: 4.8,
    azimuth: 254.88,
    tvd: 139.93,
    dogLegSeverity: 0.8
  },
  {
    id: 4,
    md: 130,
    inclination: 4,
    azimuth: 254.88,
    tvd: 129.96,
    dogLegSeverity: 0.8
  },
  {
    id: 3,
    md: 120,
    inclination: 3.2,
    azimuth: 254.88,
    tvd: 119.98,
    dogLegSeverity: 0.8
  },
  {
    id: 2,
    md: 110,
    inclination: 2.4,
    azimuth: 254.88,
    tvd: 109.99,
    dogLegSeverity: 0.8
  },
  {
    id: 1,
    md: 100,
    inclination: 1.6,
    azimuth: 254.88,
    tvd: 100,
    dogLegSeverity: 0.8
  }
];

// Intersection

export const checkIsWellCreated = async (
  wellUid: string
): Promise<ITrajectoryWell | null> => {
  try {
    const well: ITrajectoryWellBack = await request.get(
      proxyCalcApi(`well/well/${wellUid}/`)
    );
    return trajectoryWellToFront(well);
  } catch (e: any) {
    console.dir(e);
    return null;
  }
};

export const createTrajectoryWell = async (
  wellId: string,
  toolId: string
): Promise<ITrajectoryWell> => {
  const data = {
    item_uuid: wellId,
    survey_tool_uuids: [toolId],
    item_meta: {},
    item_data: {},
    type: null
  };
  const well: ITrajectoryWellBack = await request.post(
    proxyCalcApi('well/well/'),
    data
  );
  return trajectoryWellToFront(well);
};

export const linkToolToWell = async (
  wellUid: string,
  surveyToolUid: string
): Promise<ITrajectoryWell> => {
  const body = {
    survey_tool_uuids: [surveyToolUid],
    item_meta: {},
    item_data: {},
    type: null
  };
  return request.put(proxyCalcApi(`well/well/${wellUid}/`), body);
};

export const createTrajectoryCalc = async (
  wellUid: string
): Promise<ITrajectoryCalc> => {
  const body = {
    item_meta: null,
    item_data: null,
    well_uuid: wellUid
  };
  const trajectoryCalc: ITrajectoryCalcBack = await request.post(
    proxyCalcApi('research/trajectory_calc/'),
    body
  );
  return trajectoryCalcToFront(trajectoryCalc);
};

export const getTrajectoryCalc = async (
  targetWellId: string
): Promise<ITrajectoryCalc | null> => {
  const trajectoryCalcs: ITrajectoryCalcBack[] = await request.get(
    proxyCalcApi(`research/trajectory_calc/?well_uuid=${targetWellId}`)
  );
  return trajectoryCalcs[0] ? trajectoryCalcToFront(trajectoryCalcs[0]) : null;
};

export const getProfileCalc = async (
  trajectoryCalcId: string
): Promise<IProfileCalc[]> =>
  request.get(proxyCalcApi(`research/profile_calc/${trajectoryCalcId}/`));

export const createNudgeCalcApi = async (
  body: NudgeCalcCreateBackType
): Promise<NudgeCalcBackType> =>
  request.post(proxyCalcApi('research/nudge_calc/'), body);

export const editNudgeCalcApi = async (
  body: NudgeCalcCreateBackType,
  id: string
): Promise<NudgeCalcBackType> =>
  request.put(proxyCalcApi(`research/nudge_calc/${id}/`), body);

export const createJProfileCalcApi = async (
  body: JProfileCalcCreateBackType
): Promise<JProfileCalcBackType> =>
  request.post(proxyCalcApi('research/j_profile_calc/'), body);

export const editJProfileCalcApi = async (
  body: JProfileCalcCreateBackType,
  id: string
): Promise<JProfileCalcBackType> =>
  request.put(proxyCalcApi(`research/j_profile_calc/${id}/`), body);

export const getJProfileCalcByIdApi = async (item_uuid: string) =>
  request.get(proxyCalcApi(`research/j_profile_calc/${item_uuid}/`));

export const getSProfileCalcByIdApi = async (item_uuid: string) =>
  request.get(proxyCalcApi(`research/s_profile_calc/${item_uuid}/`));

export const getNudgeCalcByIdApi = async (item_uuid: string) =>
  request.get(proxyCalcApi(`research/nudge_calc/${item_uuid}/`));

export const createSProfileCalcApi = async (
  body: SProfileCalcCreateBackType
): Promise<SProfileCalcBackType> =>
  request.post(proxyCalcApi('research/s_profile_calc/'), body);

export const editSProfileCalcApi = async (
  body: SProfileCalcCreateBackType,
  id: string
): Promise<SProfileCalcBackType> =>
  request.put(proxyCalcApi(`research/s_profile_calc/${id}/`), body);

export const createOptimumAlignCalcApi = async (
  body: OptimumAlignCreateBackType
): Promise<OptimumAlignBackType> =>
  request.post(proxyCalcApi('research/optimum_align_chc_calc/'), body);

export const editOptimumAlignCalcApi = async (
  body: OptimumAlignCreateBackType,
  optimumId: string
): Promise<OptimumAlignBackType> =>
  request.post(
    proxyCalcApi(`research/optimum_align_chc_calc/${optimumId}/`),
    body
  );

export const getOptimumAlignCalcByIdApi = async (item_uuid: string) =>
  request.get(proxyCalcApi(`research/optimum_align_chc_calc/${item_uuid}/`));

export const getAnticollisionCalc = async (
  anticollisionCalcId: string
): Promise<IAnticollisionCalc> => {
  const anticollisionCalc: IAnticollisionCalcBack = await request.get(
    proxyCalcApi(`research/anticollision_calc/${anticollisionCalcId}/`)
  );
  return anticollisionCalcToFront(anticollisionCalc);
};

export const linkOffsetWells = async (
  wellUids: string[],
  trajectoryCalcId: string,
  anticollisionId: string
): Promise<IAnticollisionCalcBack> => {
  const body = {
    offset_wells_uuids: wellUids,
    item_meta: {},
    item_data: {},
    // some default data
    sigma: 2,
    interp_md_step: 10,
    error_surface_type: 'ellipt_conic',
    max_min_dist: 0,
    max_sf: 0,
    max_ellipse_dist: 0,
    linked_with_trajectory_calc: trajectoryCalcId
  };

  return request.put(
    proxyCalcApi(`research/anticollision_calc/${anticollisionId}/`),
    body
  );
};

export const getSurveyTool = async (): Promise<ISurveyTool[]> => {
  const tools: ISurveyToolBack[] = await request.get(
    proxyCalcApi('survey_tool/survey_tool/')
  );
  return tools.map((tool) => toolToFront(tool));
};

export const anticollisionCalculation = async (
  trajectoryCalcId: string
): Promise<IAnticollisionCalculation> => {
  const body = {
    trajectory_calc_uuid: trajectoryCalcId
  };
  return request.post(proxyCalcApi('calculation/anticollision/'), body);
};

export const deleteJProfile = async (
  id: string
): Promise<JProfileCalcBackType> =>
  request.delete(calcTrajectoryApi(`delete_jprofile/${id}`));

export const deleteSProfile = async (
  id: string
): Promise<SProfileCalcBackType> =>
  request.delete(calcTrajectoryApi(`delete_sprofile/${id}`));

export const deleteNudgeProfile = async (
  id: string
): Promise<NudgeCalcBackType> =>
  request.delete(calcTrajectoryApi(`delete_nudge/${id}`));

export const deleteOptimumAlign = async (
  id: string
): Promise<NudgeCalcBackType> =>
  request.delete(calcTrajectoryApi(`delete_optimum_align_chc_calc/${id}`));

export const downloadAnticollisionReportApi = async (body: {
  well_uuid: string;
}): Promise<AxiosResponse<Blob>> =>
  request.instance({
    url: proxyCalcApi('report/anticollision/'),
    method: 'POST',
    responseType: 'blob',
    headers: {
      Authorization: getTokenString()
    },
    data: body
  });

export const downloadForecastReportApi = async (body: {
  well_uuid: string;
}): Promise<AxiosResponse<Blob>> =>
  request.instance({
    url: proxyCalcApi('report/well_plan/'),
    method: 'POST',
    responseType: 'blob',
    headers: {
      Authorization: getTokenString()
    },
    data: body
  });

export const getFpAnalysisApi = async (
  wellUid: string
): Promise<FpAnalysisBackType[]> =>
  request.get(
    proxyCalcApi(`research/fp_analysis/?linked_with_well=${wellUid}`)
  );
