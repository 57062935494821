import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'primereact/badge';

import { messageTypes, NotificationType } from 'models/notification/types';
import { useClassName } from '../../../utils/cn';

type ProjectContentPropsType = {
  payload: NotificationType
  type: messageTypes
};

type IncidentContentProps = { incidentId: string, incidentTypeName: string } | null;

export const ProjectContent: React.FC<ProjectContentPropsType> = ({ payload, type }) => {
  const cn = useClassName('header-notification');
  const { t } = useTranslation('common', { keyPrefix: 'header' });
  const messages: { [key in messageTypes]?: {
    title: string,
    description: string,
    message: string
  } } = ({
    [messageTypes.PROJECT_CREATED]: {
      title: `${t('new-project')} `,
      description: `${t('new-project-added')}
       `,
      message: payload.wellName
    },
    [messageTypes.WARNING_WITHOUT_CIRCULATION]: {
      title: 'Проработка происходит без циркуляции',
      description: 'Скважина - ',
      message: payload.wellName
    },
    [messageTypes.WELL_CREATED]: {
      title: t('new-well'),
      description: t('new-well-added'),
      message: payload.wellName
    },
    [messageTypes.FINISH_DRILL]: {
      title: 'Бурение завершено',
      description: 'Бурение завершено ',
      message: payload.wellName
    },
    [messageTypes.WITSML_CREATED]: {
      title: 'Новая скважина в бурении',
      description: 'Новая скважина в бурении - ',
      message: payload.wellName
    }
  });
  const { title = '', description = '', message = '' } = messages[type] || {};

  return (
    <>
      <div className={cn('content-title')}>
        <Badge severity="danger" className="mr-2" />
        <h4>
          {title}
        </h4>
      </div>
      <div className={cn('content-body')}>
        {description}
        {message}
      </div>
    </>
  );
};
