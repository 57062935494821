import React, { useEffect, useRef } from 'react';

import lottie from 'lottie-web';

interface ILottieProps {
  animationData: any
  width: number
  height: number
}

export const Lottie: React.FC<ILottieProps> = ({ animationData, width, height }) => {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<any>(null);

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        animationData,
        container: element.current
      });
    }
    return () => {
      lottieInstance.current?.destroy();
    };
  }, [animationData]);

  return <div style={{ width, height }} ref={element} />;
};
