// @ts-nocheck
import { DrillingOperationStatusType } from 'models/documentation/types';

// to suit your point format, run search/replace for '.beginMd' and '.beginDateTimeInclusive';
// for 3D version, see 3d branch (configurability would draw significant performance overhead)

// square distance between 2 points

function getSqDist(p1, p2) {
  const dx = p1.beginMd - p2.beginMd;
  const dy = p1.beginDateTimeInclusive - p2.beginDateTimeInclusive;

  return dx * dx + dy * dy;
}

// square distance from a point to a segment
function getSqSegDist(p, p1, p2) {
  let x = p1.beginMd;
  let y = p1.beginDateTimeInclusive;
  let dx = p2.beginMd - x;
  let dy = p2.beginDateTimeInclusive - y;

  if (dx !== 0 || dy !== 0) {
    const t = ((p.beginMd - x) * dx + (p.beginDateTimeInclusive - y) * dy) / (dx * dx + dy * dy);

    if (t > 1) {
      x = p2.beginMd;
      y = p2.beginDateTimeInclusive;
    } else if (t > 0) {
      x += dx * t;
      y += dy * t;
    }
  }

  dx = p.beginMd - x;
  dy = p.beginDateTimeInclusive - y;

  return dx * dx + dy * dy;
}
// rest of the code doesn't care about point format

// basic distance-based simplification
function simplifyRadialDist(points: DrillingOperationStatusType[], sqTolerance) {
  let prevPoint = points[0];
  const newPoints = [prevPoint];
  let point;

  for (let i = 1, len = points.length; i < len; i++) {
    point = points[i];

    if (getSqDist(point, prevPoint) > sqTolerance) {
      newPoints.push(point);
      prevPoint = point;
    }
  }

  if (prevPoint !== point) newPoints.push(point);

  return newPoints;
}

function simplifyDPStep(points: DrillingOperationStatusType[], first, last, sqTolerance, simplified) {
  let maxSqDist = sqTolerance;
  let index;

  for (let i = first + 1; i < last; i++) {
    const sqDist = getSqSegDist(points[i], points[first], points[last]);

    if (sqDist > maxSqDist) {
      index = i;
      maxSqDist = sqDist;
    }
  }

  if (maxSqDist > sqTolerance) {
    if (index - first > 1) simplifyDPStep(points, first, index, sqTolerance, simplified);
    simplified.push(points[index]);
    if (last - index > 1) simplifyDPStep(points, index, last, sqTolerance, simplified);
  }
}

// simplification using Ramer-Douglas-Peucker algorithm
function simplifyDouglasPeucker(points: DrillingOperationStatusType[], sqTolerance) {
  const last = points.length - 1;
  const simplified = [points[0]];
  simplifyDPStep(points, 0, last, sqTolerance, simplified);
  simplified.push(points[last]);

  return simplified;
}

// both algorithms combined for awesome performance
function simplify(points: DrillingOperationStatusType[], tolerance?: number, highQuality?: boolean) {
  if (points.length <= 2) return points;

  const sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

  points = highQuality ? points : simplifyRadialDist(points, sqTolerance);
  points = simplifyDouglasPeucker(points, sqTolerance);

  return points;
}

export default simplify;