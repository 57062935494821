import SideMenu from 'components/side-menu';
import React, { JSX, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../header';
import ErrorBoundary from '../../components/error-boundary/component';
import './style.scss';

export default ({ children }: any): JSX.Element => {
  const [isSmall, setIsSmall] = useState(true);

  return (
    <div className="flex layout-wrapper">
      <SideMenu isSmall={isSmall} setIsSmall={setIsSmall} />

      <div className="layout-main">
        <Header />
        <div className="layout-container">
          <ErrorBoundary navigate={useNavigate()}>
            <div
              className="layout-content"
              style={{ width: `calc(100vw - ${isSmall ? 'var(--sidebar-min-width)' : 'var(--sidebar-max-width)'} - 8px)` }}
            >
              <Outlet />
            </div>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};
