export enum messageTypes {
    PREDICTED_RESULT = 'PREDICTED_RESULT', // оповещение об осложнении
    PROJECT_CREATED = 'PROJECT_CREATED', // оповещение о создании проекта
    WITSML_CREATED = 'WITSML_CREATED', // автоматическое создание скважины
    FINISH_DRILL = 'FINISH_DRILL', // окончание бурения
    WARNING_WITHOUT_CIRCULATION = 'WARNING_WITHOUT_CIRCULATION', // оповещение о проработке без циркуляции ствола
    WELL_CREATED = 'WELL_CREATED', // оповещение о создании скважины когда приходят новые мнемоники
    INCIDENT_CLOSE = 'INCIDENT_CLOSE' // закрытие инцидента (для фактических) отрабатывает на closeFactIncidentById
}

export enum NotificationFilter {
    all = 'all',
    read = 'read',
    notRead = 'not-read',
}

export type NotificationType = {
    id: string
    wellUid: string
    wellName: string
    type: messageTypes
    payload: any
    createdAt: string
    createdAtTimestamp: string
    read: boolean
};