import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CriticalityType,
  Effective,
  FactIncident,
  FactWithoutLiq,
  IncidentKind,
  IncidentResult,
  IncidentSet,
  IncidentSubstance,
  IncidentType,
  IResponseType,
  PlanIncident, UnitOfMeasureType,
  UnknownIncident
} from './types';
import {
  createFactIncident,
  createPlanIncident, deleteAllPlanIncidents,
  deleteIncident, editPlanIncident,
  getCriticalityType, getIncidentByInterval,
  getIncidentByWellId,
  getIncidentFactByWellId,
  getIncidentResult,
  getIncidentSet,
  getIncidentSubstance,
  getIncidentType,
  getResponseType, getUnitOfMeasure, liquidateIncident
} from './actions';

interface IState {
  error: string;
  isLoading: boolean;
  allPageLoading: boolean;
  responseTypes: IResponseType[];
  incidentSets: IncidentSet[]; // группа осложнений
  incidentTypes: IncidentType[];
  incidentSubstances: IncidentSubstance[];
  criticalityTypes: CriticalityType[];
  incidentResults: IncidentResult[];
  factIncidents: FactIncident[];
  planIncidents: PlanIncident[];
  units: UnitOfMeasureType[];
}

const initialState: IState = {
  incidentSets: [],
  incidentSubstances: [],
  incidentTypes: [],
  responseTypes: [],
  criticalityTypes: [],
  incidentResults: [],
  factIncidents: [],
  planIncidents: [],
  units: [],
  error: '',
  isLoading: false,
  allPageLoading: false
};

export const incidentSlice = createSlice({
  name: 'incident',
  initialState,
  reducers: {},
  extraReducers: {
    [getResponseType.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [getResponseType.fulfilled.type]: (state: IState, action: PayloadAction<IResponseType[]>) => {
      state.responseTypes = action.payload;
      state.isLoading = false;
    },
    [getResponseType.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [getIncidentSet.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [getIncidentSet.fulfilled.type]: (state: IState, action: PayloadAction<IncidentSet[]>) => {
      state.incidentSets = action.payload;
      state.isLoading = false;
    },
    [getIncidentSet.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [getIncidentType.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [getIncidentType.fulfilled.type]: (state: IState, action: PayloadAction<IncidentType[]>) => {
      state.incidentTypes = action.payload;
      state.isLoading = false;
    },
    [getIncidentType.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [getIncidentSubstance.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [getIncidentSubstance.fulfilled.type]: (state: IState, action: PayloadAction<IncidentSubstance[]>) => {
      state.incidentSubstances = action.payload;
      state.isLoading = false;
    },
    [getIncidentSubstance.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [getCriticalityType.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [getCriticalityType.fulfilled.type]: (state: IState, action: PayloadAction<CriticalityType[]>) => {
      state.criticalityTypes = action.payload;
      state.isLoading = false;
    },
    [getCriticalityType.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [getIncidentResult.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [getIncidentResult.fulfilled.type]: (state: IState, action: PayloadAction<IncidentResult[]>) => {
      state.incidentResults = action.payload.sort((a, b) => Number(b.response_result) - Number(a.response_result));
      state.isLoading = false;
    },
    [getIncidentResult.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [createFactIncident.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [createFactIncident.fulfilled.type]: (state: IState, action: PayloadAction<FactWithoutLiq>) => {
      state.isLoading = false;

      const incident = { ...action.payload };
      const isLiq = incident.hseIncidentResponseList.some(res => res.responseResult === Effective.YES);
      state.factIncidents.push({
        ...incident,
        liquidated: isLiq
      });
    },
    [createFactIncident.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    // Deprecated
    [getIncidentByWellId.pending.type]: (state: IState) => {
      state.allPageLoading = true;
    },
    [getIncidentByWellId.fulfilled.type]: (state: IState, action: PayloadAction<UnknownIncident[]>) => {
      // @ts-ignore
      state.planIncidents = action.payload.filter(incident => incident.incidentKind === IncidentKind.PLAN);
      state.factIncidents = action.payload
        .filter(incident => incident.incidentKind === IncidentKind.FACT)
        .map(incident => {
          const isLiq = incident.hseIncidentResponseList.some(res => res.responseResult === Effective.YES);
          return {
            ...incident,
            liquidated: isLiq,
            incidentKind: IncidentKind.FACT
          };
        });
      state.allPageLoading = false;
    },
    [getIncidentByWellId.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.allPageLoading = false;
    },

    [getIncidentFactByWellId.pending.type]: (state: IState) => {
      state.allPageLoading = true;
    },
    [getIncidentFactByWellId.fulfilled.type]: (state: IState, action: PayloadAction<UnknownIncident[]>) => {
      state.factIncidents = action.payload
        .map(incident => {
          const isLiq = incident.hseIncidentResponseList.some(res => res.responseResult === Effective.YES);
          return {
            ...incident,
            liquidated: isLiq,
            incidentKind: IncidentKind.FACT
          };
        });
      state.allPageLoading = false;
    },
    [getIncidentFactByWellId.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.allPageLoading = false;
    },

    [deleteIncident.pending.type]: (state: IState) => {
      state.allPageLoading = true;
    },
    [deleteIncident.fulfilled.type]: (state: IState, action: PayloadAction<{ incidentId: string }>) => {
      state.allPageLoading = false;
      // we can delete only Plan incidents
      const index = state.planIncidents.findIndex(incident => incident.incidentId === action.payload.incidentId);
      if (index >= 0) {
        state.planIncidents.splice(index, 1);
      }
    },
    [deleteIncident.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.allPageLoading = false;
    },

    [liquidateIncident.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [liquidateIncident.fulfilled.type]: (state: IState, action: PayloadAction<FactIncident>) => {
      // data in response not updated, that is why can`t update state here
      // need to refetch incidents
      state.isLoading = false;
    },
    [liquidateIncident.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [createPlanIncident.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [createPlanIncident.fulfilled.type]: (state: IState, action: PayloadAction<PlanIncident>) => {

    },
    [createPlanIncident.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [editPlanIncident.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [editPlanIncident.fulfilled.type]: (state: IState, action: PayloadAction<PlanIncident>) => {
      const index = state.planIncidents.findIndex(incident => incident.incidentId === action.payload.incidentId);
      if (index >= 0) {
        state.planIncidents[index] = action.payload;
      }
      state.isLoading = false;
    },
    [editPlanIncident.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [getUnitOfMeasure.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [getUnitOfMeasure.fulfilled.type]: (state: IState, action: PayloadAction<UnitOfMeasureType[]>) => {
      state.units = action.payload;
    },
    [getUnitOfMeasure.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [getIncidentByInterval.pending.type]: (state: IState) => {
      state.allPageLoading = true;
    },
    [getIncidentByInterval.fulfilled.type]: (state: IState, action: PayloadAction<PlanIncident[]>) => {
      state.planIncidents = action.payload;
      state.allPageLoading = false;
    },
    [getIncidentByInterval.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.allPageLoading = false;
    },

    [deleteAllPlanIncidents.pending.type]: (state: IState) => {
      state.allPageLoading = true;
    },
    [deleteAllPlanIncidents.fulfilled.type]: (state: IState) => {
      state.planIncidents = [];
      state.allPageLoading = false;
    },
    [deleteAllPlanIncidents.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.allPageLoading = false;
    }
  }
});

export default incidentSlice.reducer;
