const options = {
  name: 'well',
  apiUrl: '/well',

  pickupName: 'wellpickup',
  pickupApiUrl: '/wellpickup',
  copyApiUrl: '/strat/well/unit',

  serviceUrl: '/well'
};

export default options;
