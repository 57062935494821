import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';

import { messageTypes, NotificationType } from 'models/notification/types';
import { formatDate } from '../../../utils/date';
import { useClassName } from '../../../utils/cn';
import { NotificationPayloadType } from '.';

type NotificationElementPropsType = {
  payload: NotificationPayloadType,
  notification: NotificationType,
  handleButtonClick: () => void
};

export const NotificationElement: React.FC<PropsWithChildren<NotificationElementPropsType>> = ({
  payload, notification, handleButtonClick, children
}) => {
  const cn = useClassName('header-notification');
  const { t } = useTranslation('common', { keyPrefix: 'header' });
  const date = notification.type === messageTypes.PREDICTED_RESULT ?
    payload.mnemonicDate :
    notification.createdAtTimestamp;
  return (
    <div className={cn('content-wrapper')}>
      {children}
      <p className={cn('content-date')}>
        {formatDate(date, 'dd.MM.yyyy, HH:mm')}
      </p>
      <Button
        label={t('details')}
        className={`p-button-outlined ${cn('details-button')}`}
        onClick={handleButtonClick}
      />
    </div>
  );
};
